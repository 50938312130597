<div class="modal-close" *ngIf="type != 'profile'" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<app-loading-icon *ngIf="loading"></app-loading-icon>
<ng-container *ngIf="!loading">
  <div class="p-4">
    <div *ngIf="title">
      <h1 class="text-center">{{ title }}</h1>
      <p class="text-muted text-center fs-14">
        {{ "film:from" | translate }} {{ video?.source?.origin?.title }}
      </p>
    </div>
    <hr />
    <div *ngIf="description">
      <p class="text-center fw-bold">
        {{ "videopage:introduction" | translate }}
      </p>
      <p class="text-break fs-15">{{ description }}</p>
    </div>
  </div>

  <app-video-player [video]="video"></app-video-player>

  <div class="p-4 mt-3">
    <div class="container">
      <div class="row align-items-center">
        <div class="col text-muted">
          <fa-icon class="icon me-1" [icon]="faEye"></fa-icon>
          <span>{{ video?.stats?.views }}</span>

          <fa-icon class="icon ms-4 me-1" [icon]="faStar"></fa-icon>
          <span>{{ rating }}</span>
        </div>
        <div
          *ngIf="video_exist"
          class="col text-muted link-copy"
          (click)="copyLink()"
        >
          <span *ngIf="copied" [ngClass]="{ copied: copied }"
            ><i class="fa fa-check"></i> Link copied!&nbsp;</span
          >
          <span *ngIf="!copied">Copy video link</span>
        </div>
        <div class="col-auto">
          {{ group }}
          <div class="dropdown" *ngIf="UserCanEdit()">
            <button
              type="button"
              class="btn btn-outline-primary btn-sm"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ "play_video:more:dropdown" | translate }}
              <i class="fa fa-angle-down ms-1"></i>
            </button>
            <ul
              class="dropdown-menu dropdown-menu-movie dropdown-menu-center dropdown-bubble"
            >
              <li>
                <a class="dropdown-item" (click)="goEdit()">{{
                  "play_video:edit:dropdown" | translate
                }}</a>
              </li>
              <li>
                <a (click)="duplicateVideo()" class="dropdown-item">{{
                  "play_video:duplicate:button" | translate
                }}</a>
              </li>
              <li>
                <a
                  (click)="deleteVideModal(video?.id)"
                  class="dropdown-item text-danger"
                  >{{ "video:edit:modal:delete_video:button" | translate }}</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-auto">
          <button (click)="openModalMicroclip()" class="btn btn-primary">
            {{ "Microclips" | translate }}
          </button>
        </div>
      </div>
      <hr />

      <div class="row">
        <app-movie-tabs-wrapper>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active py-3"
              data-bs-toggle="pill"
              data-bs-target="#actions-tab"
              type="button"
              role="tab"
              aria-controls="actions"
              aria-selected="true"
            >
              {{ "play_video:actions:label" | translate }}
            </button>
          </li>
          <li
            class="nav-item"
            role="presentation"
            *ngIf="
              (video?.contexts?.length || video?.double_helix_analysis) &&
              isDHOrg
            "
          >
            <button
              class="nav-link py-3"
              data-bs-toggle="pill"
              data-bs-target="#dh-tab"
              type="button"
              role="tab"
              aria-controls="dh"
              aria-selected="false"
            >
              {{ "play_video:dh_tags:label" | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link py-3"
              data-bs-toggle="pill"
              data-bs-target="#tags-tab"
              type="button"
              role="tab"
              aria-controls="tags"
              aria-selected="false"
            >
              {{ "play_video:tags:label" | translate }}
            </button>
          </li>
          <li
            class="nav-item"
            role="presentation"
            *ngIf="video?.source?.origin_type && video?.source?.origin?.title"
          >
            <button
              class="nav-link py-3"
              data-bs-toggle="pill"
              data-bs-target="#film-tab"
              type="button"
              role="tab"
              aria-controls="film"
              aria-selected="false"
            >
              {{ "play_video:details:label" | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link py-3"
              data-bs-toggle="pill"
              data-bs-target="#description-tab"
              type="button"
              role="tab"
              aria-controls="description"
              aria-selected="false"
            >
              {{ "play_video:description:label" | translate }}
            </button>
          </li>
        </app-movie-tabs-wrapper>
      </div>

      <div class="tab-content mt-3">
        <div
          class="tab-pane fade show active"
          id="actions-tab"
          role="tabpanel"
          aria-labelledby="actions-tab"
        >
          <div class="row gy-2">
            <div class="col-12 col-md-4 d-grid">
              <button (click)="openButton()" class="btn btn-cta border">
                <div
                  class="icon-container me-3"
                  style="background-color: #f25c5c"
                >
                  <svg
                    class="gift"
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="38"
                    viewBox="0 0 30 25"
                    style="padding-top: 12px"
                  >
                    <g
                      fill="none"
                      fill-rule="evenodd"
                      stroke="#fff"
                      transform="translate(1 1)"
                    >
                      <path
                        stroke-width="1.8"
                        d="M14.108 11.295c.89 0 1.71-.302 2.353-.807.758.347 2.061.924 3.447 1.451l3.13 7.054-3.76-.916-1.88 3.669-3.29-8.616v-1.835zM14.105 11.295c-.89 0-1.709-.302-2.353-.807-.758.347-2.06.924-3.446 1.451l-3.13 7.054 3.76-.916 1.879 3.669 3.29-8.616v-1.835z"
                      ></path>
                      <path
                        stroke-width="1.8"
                        d="M11.756 10.488c-1.668.763-5.974 2.642-7.986 2.642a2.38 2.38 0 0 1-1.717-.692C.859 11.248.909 8.851.943 7.266l.007-.557c0-.343-.078-.816-.158-1.335C.548 3.797.273 2.01 1.183.978c.279-.315.79-.69 1.647-.69 2.425 0 6.188 1.844 9.05 4.383a3.637 3.637 0 0 0-1.531 2.955 3.63 3.63 0 0 0 1.407 2.862zM16.337 4.67c2.861-2.538 6.625-4.382 9.05-4.382.857 0 1.368.375 1.646.69.91 1.033.635 2.82.392 4.396-.08.519-.159.992-.159 1.335l.009.557c.033 1.585.083 3.982-1.11 5.172a2.38 2.38 0 0 1-1.718.692c-2.012 0-6.318-1.879-7.986-2.642a3.63 3.63 0 0 0 1.407-2.862 3.637 3.637 0 0 0-1.531-2.955z"
                      ></path>
                      <ellipse
                        cx="14.108"
                        cy="7.626"
                        stroke-width="1.5"
                        rx="3.759"
                        ry="3.669"
                      ></ellipse>
                    </g>
                  </svg>
                </div>
                <span class="fw-bold">{{
                  "play_video:video_gift:title" | translate
                }}</span>
              </button>
            </div>
            <div class="col-12 col-md-4 d-grid">
              <button
                *ngIf="!votingActive"
                class="btn btn-cta border"
                placement="bottom"
                [ngbTooltip]="tooltip"
                container="body"
                (click)="toggleVoting()"
              >
                <div
                  class="icon-container me-3"
                  style="background-color: #f8e71b; padding: 5px"
                >
                  <fa-icon
                    class="icon me-1"
                    [icon]="faStar"
                    [styles]="{ color: 'white' }"
                    size="2x"
                  ></fa-icon>
                </div>
                <span class="text-start">
                  <span class="fw-bold">{{ rating }}</span
                  ><span>/5</span>
                  <br />
                  <span class="fw-bold">{{
                    userRate
                      ? ("rating-stars:my-rating" | translate) + ": " + userRate
                      : ("rating-stars:rate-this" | translate)
                  }}</span>
                </span>
              </button>

              <button
                *ngIf="votingActive"
                class="btn btn-cta border text-center"
              >
                <app-voting-stars (rate)="rate($event)"></app-voting-stars>
              </button>
            </div>
            <ng-template #tooltip>
              <div class="text-start">
                <div *ngFor="let rat of ratingList">
                  {{ rat.user.display_name }}:
                  <strong class="ms-2">{{ rat.stars }}</strong>
                </div>
              </div>
            </ng-template>
            <div class="col-12 col-md-4 d-grid">
              <button (click)="addFavorite()" class="btn btn-cta border">
                <div
                  class="icon-container me-3"
                  [ngClass]="{ 'favorite-container': videoFavorite }"
                >
                  <fa-icon
                    class="icon heart"
                    [icon]="faHeart"
                    [ngClass]="{ favorite: videoFavorite }"
                    size="2x"
                  ></fa-icon>
                </div>
                <span class="text-start fw-bold">{{
                  favoriteText | translate
                }}</span>
              </button>
            </div>
          </div>

          <div id="collapseBlock" class="" [ngbCollapse]="isCollapsed">
            <div class="row border border-radius m-0 mt-3 p-2">
              <div class="form-group mt-2">
                <label>{{
                  "play_video:video_gift:add:members" | translate
                }}</label>
                <app-search-users
                  (searchEvent)="select($event)"
                  type="video"
                  placeholder="{{
                    'play_video:video_gift:enter_name' | translate
                  }}"
                ></app-search-users>
              </div>
              <div class="form-group mt-3">
                <label>{{
                  "play_video:video_gift:add:message" | translate
                }}</label>
                <textarea
                  [(ngModel)]="textGift"
                  placeholder="{{
                    'play_video:video_gift:write_invitation' | translate
                  }}"
                  type="text"
                  class="form-control my-input input-box"
                ></textarea>
              </div>
              <div class="form-group clearfix">
                <button
                  [disabled]="
                    this.selectUsers.length == 0 || textGift.length == 0
                  "
                  (click)="sendGift()"
                  type="button"
                  role="tab"
                  class="btn btn-primary btn-create modal-btn-width float-right ms-2 fs-15 mt-4 mb-3"
                >
                  <fa-icon
                    class="ms-2"
                    [icon]="faCircleNorch"
                    *ngIf="giftLoading"
                    [spin]="true"
                  ></fa-icon>
                  {{ "play_video:video_gift:button" | translate }}
                </button>

                <a
                  (click)="cancelButton()"
                  class="btn btn-link float-right mt-4 mb-3 font-weight-Light mx-2"
                >
                  <span>
                    {{ "video:edit:modal:button:cancel" | translate }}</span
                  >
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="dh-tab"
          role="tabpanel"
          aria-labelledby="dh-tab"
          *ngIf="
            (video?.contexts?.length || video?.double_helix_analysis) && isDHOrg
          "
        >
          <div class="special-tags-row-video-profile row clearfix">
            <div
              class="special-tag-col-container col-md-4"
              *ngFor="let dh_tag of dh_tags; let i = index"
            >
              <div
                class="special-tag-outer-container level-{{
                  dh_tag.level.level
                }}"
              >
                <div class="special-tag-background">
                  <div
                    *ngFor="let circleItem of circleItems"
                    class="special-tag-circle special-tag-circle-{{
                      circleItem
                    }}"
                    [ngClass]="{
                      'special-tag-circle-broad-stroke': isPhaseSelected(
                        i,
                        circleItem
                      ),
                      'special-tag-circle-full': !isPhaseSelected(i, circleItem)
                    }"
                  ></div>
                </div>
                <h3 class="text-center">
                  {{ "play_video:dh_tag:field" | translate }}
                  {{ dh_tag.phase.phase }} {{ dh_tag.phase.info[0].name }}
                </h3>
                <h4 class="text-center">{{ dh_tag.special.info[0].name }}</h4>
              </div>
              <div class="special-tag-button text-center">
                <button
                  class="btn btn-outline-primary btn-sm"
                  ng-click="learnMore(dh_tag)"
                >
                  {{ "play_video:dh_tag:more" | translate }}
                </button>
              </div>
            </div>
          </div>
          <div
            class="double-healix-analysis"
            *ngIf="video?.double_helix_analysis"
          >
            <h3 class="h5">{{ "play_video:dh_tags:label" | translate }}</h3>
            <p>{{ video?.double_helix_analysis }}</p>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="tags-tab"
          role="tabpanel"
          aria-labelledby="tags-tab"
          *ngIf="!loadingTags"
        >
          <div class="pills np-bottom">
            <div
              class="badge rounded-pill bg-light text-dark border p-2 me-2"
              *ngFor="let tag of tags"
            >
              {{ tag.info.name }}
            </div>
            <div
              class="badge rounded-pill bg-light text-dark border p-2 me-2"
              *ngFor="let tag of userTags"
            >
              <span>{{ tag.info.name }}</span>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="film-tab"
          role="tabpanel"
          aria-labelledby="film-tab"
          *ngIf="video?.source?.origin_type && video?.source?.origin?.title"
        >
          <div class="row">
            <div class="col-2" *ngIf="video?.source?.origin?.poster">
              <img [src]="video?.source?.origin?.poster" width="100%" />
            </div>
            <div class="col">
              <h3 class="h5 mt-2" *ngIf="video?.source?.origin?.title">
                {{ video?.source?.origin?.title }}
                <span class="text-muted" *ngIf="video?.source?.origin?.year"
                  >({{ video?.source?.origin?.year }})</span
                >
              </h3>
              <p class="mt-3" *ngIf="video?.source?.origin?.plot">
                {{ video?.source?.origin?.plot }}
              </p>
              <ul class="list-unstyled mt-3">
                <li *ngIf="video?.source?.origin?.genre">
                  <strong
                    >{{ "play_video:details:genre:label" | translate }}:</strong
                  >
                  {{ video?.source?.origin?.genre }}
                </li>
                <li *ngIf="video?.source?.origin?.writer">
                  <strong
                    >{{
                      "play_video:details:writer:label" | translate
                    }}:</strong
                  >
                  {{ video?.source?.origin?.writer }}
                </li>
                <li *ngIf="video?.source?.origin?.director">
                  <strong
                    >{{
                      "play_video:details:directed:label" | translate
                    }}:</strong
                  >
                  {{ video?.source?.origin?.director }}
                </li>
                <li *ngIf="video?.source?.origin?.production">
                  <strong
                    >{{
                      "play_video:details:production:label" | translate
                    }}:</strong
                  >
                  {{ video?.source?.origin?.production }}
                </li>
                <li *ngIf="video?.source?.origin?.copyright">
                  <strong
                    >{{
                      "play_video:details:copyright:label" | translate
                    }}:</strong
                  >
                  {{ video?.source?.origin?.copyright }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="description-tab"
          role="tabpanel"
          aria-labelledby="description-tab"
        >
          <p class="text-break fs-14">{{ description }}</p>
          <div class="row gx-2">
            <div class="col-auto">
              <app-user-icon [user]="video?.user || null"></app-user-icon>
            </div>
            <div class="col-auto d-flex flex-column justify-content-center">
              <span class="text-muted fs-14"
                >{{ "author:by" | translate }} {{ video?.user?.display_name }}
                {{ "play_video:uploaded" | translate }}
                {{ video?.created_at | amTimeAgo }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <app-comments-container
          [video]="video"
          [playListId]="playlistId"
        ></app-comments-container>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="type == 'profile'">
    <div class="row justify-content-center mt-5">
      <div class="col-xl-10">
        <div class="row mb-5">
          <!-- <div class="col"> -->
          <app-carousel
            [items]="relatedVideo"
            [cols]="3"
            [title]="'play_video:related_videos:title' | translate"
          ></app-carousel>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</ng-container>
