<div class="card shadow pe-pointer" [ngClass]="{ featured: featured }"
[ngStyle]="{ 'cursor': fromCompetencies?'default':'pointer' }"
>
  <span class="badge badge-item-type text-primary px-3">{{
    "courses:course" | translate
  }}</span>
  <div
    *ngIf="isFromGroup && userCanEdit(course)"
    ngbDropdown
    class="btn-toggle text-primary border rounded"
    placement="bottom-right"
  >
    <button type="button" class="btn" id="dropdownBasic1" ngbDropdownToggle>
      <i class="fa fa-ellipsis-h"></i>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="mt-1">
      <button (click)="deleteItem()" class="text-danger" ngbDropdownItem>
        {{ "groups:profile:courses:delete" | translate }}
      </button>
    </div>
  </div>

  <div
    (click)="goToCourse(course)"
    *ngIf="
      hasPrices() &&
      course.is_discounted == true  && showIncard(course)
    "
    class="ribbon ribbon-top-right"
  >
    <span *ngIf="userLanguage == 'en'">{{
      course.discount_badge_en?course.discount_badge_en:("discount:default:badge" | translate)
    }}</span>
    <span *ngIf="userLanguage == 'nl'">{{
      course.discount_badge_nl?course.discount_badge_nl:("discount:default:badge" | translate)
    }}</span>
  </div>

  <img
    (click)="goToCourse(course)"
    *ngIf="course.coverPathUrlMedium"
    [src]="course.coverPathUrlMedium"
    class="card-img-top"
  />
  <div *ngIf="!course.coverPathUrlMedium" class="card-img-top"   (click)="goToCourse(course)"></div>

  <div (click)="goToCourse(course)" class="card-body position-relative">
    <ng-container
      *ngIf="
        !hasPrices() || userIsEnrolled() || userIsInvited();
        else hasPlanDiv
      "
    >
      <span
        class="badge rounded-pill p-2 px-3 bg-success text-white position-absolute fw-normal"
      >
        {{ "courses:status:" + course?.status | translate }}
      </span>
    </ng-container>
    <ng-template #hasPlanDiv >   
      <ng-container  *ngIf="hasPlan();else fromPrice">
        <span          
            class="badge rounded-pill p-2 px-3 bg-success text-white position-absolute fw-normal"
          >
            {{ "subscription:plan:included:text" | translate }}     
        </span>
      </ng-container> 
    </ng-template>


    
    <ng-template #fromPrice>
      <ng-container
        *ngIf="
          hasPrices() && !(userSubscriptionExpired() && userWasEnrolled());
          else renewPill
        "
      >
        <span
          class="badge rounded-pill p-2 px-3 bg-primary text-white position-absolute fw-normal"
        >
          {{ "course:buy:price:from" | translate }}
          <span
            class="text-decoration-line-through"
            *ngIf="course.is_discounted == true && course?.striked_price != null && course?.striked_price > getMinSubscriptionPrice()"
          >
            {{
              course?.striked_price | currency : "EUR" : "symbol" : "1.0-2"
            }}</span
          >
          <span>
            {{
              getMinSubscriptionPrice() | currency : "EUR" : "symbol" : "1.0-2"
            }}</span
          >
        </span>
      </ng-container>
    </ng-template>

    <ng-template #renewPill>
      <ng-container *ngIf="hasRenewPrices(); else renewFrom">
        <span
          class="badge rounded-pill p-2 px-3 bg-warning text-white position-absolute fw-normal"
        >
          {{ "course:subscription:renew:from" | translate }}
          {{ getMinRenewPrice() | currency : "EUR" : "symbol" : "1.0-2" }}
        </span>
      </ng-container>
      <ng-template #renewFrom>
        <span
          class="badge rounded-pill p-2 px-3 bg-warning text-white position-absolute fw-normal"
        >
          {{ "course:subscription:renew:subscription" | translate }}
        </span>
      </ng-template>
    </ng-template>

    <h3 class="card-title mb-0 mt-2 fw-normal" [ngClass]="{ 'mb-3': featured }">
      {{ course.name }}
    </h3>

    <p class="card-text mb-1" *ngIf="!featured">
      <small class="text-muted"
        >{{ "author:by" | translate }} {{ course?.user?.display_name }}</small
      >
    </p>
    <p class="card-text short-description" *ngIf="!featured">
      {{ course.short_description | htmlToPlainText | excerpt : 145 }}
    </p>

    <div
      *ngIf="!userIsEnrolled(); else progressBar"
      class="d-flex justify-content-start align-items-center"
    >
      <small
        *ngIf="course.summaryReviews?.reviews_average"
        class="me-3 text-muted"
      >
        <fa-icon [icon]="faStar" [styles]="{ color: '#eeee00' }"></fa-icon>
        <span class="ms-1">{{ course.summaryReviews.reviews_average }}/5</span>
      </small>
      <small *ngIf="course.duration" class="me-3 text-muted">
        <fa-icon [icon]="faClock"></fa-icon>
        <span class="ms-1">{{
          course.duration + course?.qa_duration * 60000 | duration
        }}</span>
      </small>
      <small *ngIf="course.language" class="text-muted">
        <fa-icon [icon]="faComment"></fa-icon>
        <span class="ms-1">{{
          "courseprofile:settings:language:" + course.language.toLowerCase()
            | translate
        }}</span>
      </small>
    </div>
    <ng-template #progressBar>
      <div
        class="progress-bar-container"
        *ngIf="!isCompleted(); else completed"
      >
        <app-progress-bar
          [labelPosition]="'top'"
          [progress]="userProgress()"
        ></app-progress-bar>
      </div>
      <ng-template #completed>
        <div class="course-completed text-center">
          <span class="bottom-icon bottom-icon-check-circle-o"></span>
          <span class="the-bottom-text">{{
            "discover:featured:course:completed:100" | translate
          }}</span>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
