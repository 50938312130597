import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SearchSectionService {
  constructor(private api: ApiService, private auth: AuthService) {}

  async getSearchVideos(
    search: string | null,
    phase: Array<any> | null,
    level: Array<any> | null,
    special: Array<any> | null,
    subtitle: Array<any> | null,
    organization_id: Array<any> | null,
    limit: number | null = 18,
    page: number | null = 1
  ) {
    try {
      let query = `?limit=${limit}&page=${page}&search=${search || ''}${
        level?.length ? '&level[]=' + level : ''
      }${phase?.length ? '&phase[]=' + phase : ''}${
        special?.length ? '&special[]=' + special : ''
      }${
        subtitle?.length ? '&subtitle[]=' + subtitle.join('&subtitle[]=') : ''
      }${
        organization_id?.length ? '&organization_id[]=' + organization_id : ''
      }`;

      const searchVideos = await this.api.get('/search/videos' + query);
      return searchVideos;
    } catch (error) {
      return error;
    }
  }

  async getSearchPlaylist(
    search: string | null,
    phase: Array<any> | null,
    level: Array<any> | null,
    special: Array<any> | null,
    organization_id: Array<any> | null,
    limit: number | null = 18,
    page: number | null = 1
  ) {
    try {
      let query = `?limit=${limit}&page=${page}&search=${search || ''}${
        level?.length ? '&level[]=' + level : ''
      }${phase?.length ? '&phase[]=' + phase : ''}${
        special?.length ? '&special[]=' + special : ''
      }${
        organization_id?.length ? '&organization_id[]=' + organization_id : ''
      }`;
      const searchPlaylist = await this.api.get('/search/playlists' + query);
      return searchPlaylist;
    } catch (error) {
      return error;
    }
  }
  async createMicroclip(info: any | null) {
    try {
      const create = await this.api.postJson('/microclip', info);
      return create;
    } catch (error) {
      return error;
    }
  }

  async editMicroclip(info: any | null, id: any) {
    try {
      const edit = await this.api.put('/microclip/' + id, info);
      return edit;
    } catch (error) {
      return error;
    }
  }
  async deleteMicroclip(id: any | null) {
    try {
      const microclip = await this.api.delete('/microclip/' + id);
      return microclip;
    } catch (error) {
      return error;
    }
  }

  async searchMicroclip(
    search: string | null,
    phase: Array<any> | null,
    level: Array<any> | null,
    special: Array<any> | null,
    subtitle: Array<any> | null,
    organization_id: Array<any> | null,
    limit: number | null = 18,
    page: number | null = 1
  ) {
    try {
      let query = `?limit=${limit}&page=${page}&search=${search || ''}${
        level?.length ? '&level[]=' + level : ''
      }${phase?.length ? '&phase[]=' + phase : ''}${
        special?.length ? '&special[]=' + special : ''
      }${
        subtitle?.length ? '&subtitle[]=' + subtitle.join('&subtitle[]=') : ''
      }${
        organization_id?.length ? '&organization_id[]=' + organization_id : ''
      }`;

      const searchVideos = await this.api.get('/video' + query);
      return searchVideos;
    } catch (error) {
      return error;
    }
  }
}
