<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "title:require:enroll:course" | translate }}
  </h4>
</div>
<div class="modal-body">
  <p class="mb-0">{{ "chapter:require:enroll:description" | translate }}</p>
</div>
<div class="modal-footer mt-0">
  <div class="row justify-content-end align-items-center">
    <div class="col-auto px-0">
      <button
        (click)="closeModal()"
        type="button"
        class="btn btn-primary"
        autofocus="false"
      >
        {{ "btn:cancel" | translate }}
      </button>
    </div>
    <ng-template #canEnroll>
      <div *ngIf="userHasPlan" class="col-auto">
        <button
          type="button"
          role="tab"
          class="btn btn-primary text-nowrap"
          (click)="enrollToCourse()"
        >
          <fa-icon
            class="mr-2"
            [icon]="faCircleNorch"
            *ngIf="loadSpin"
            [spin]="true"
          ></fa-icon>
         {{ "collection:button:free" | translate }}
        </button>
      </div>

      <div  *ngIf="!userHasPlan" class="col-auto">
        <button
          type="button"
          role="tab"
          class="btn btn-primary text-nowrap"
          (click)="enrollCourse()"
        >
          <fa-icon
            class="mr-2"
            [icon]="faCircleNorch"
            *ngIf="loadSpin"
            [spin]="true"
          ></fa-icon>
         {{ "enrol:btn" | translate }}
        </button>
      </div>
    </ng-template>
    <div *ngIf="canBuy(); else canEnroll" class="col-auto">
      <button (click)="checkout()" class="btn btn-primary">
        {{ "courses:profile:enroll:for" | translate }}
        <span>{{
          courseUtils.getMinSubscriptionPrice(course)
            | currency : "EUR" : "symbol" : ".0"
        }}</span>
        <span
          *ngIf="course!.is_discounted && course!.striked_price > courseUtils.getMinSubscriptionPrice(course)"
          class="text-decoration-line-through ms-1"
        >
          {{ course!.striked_price | currency : "EUR" : "symbol" : ".0" }}
        </span>
        <fa-icon
          class="ms-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
      </button>
    </div>
    <div class="col-auto px-0" *ngIf="course.id == idCourseEnv">
      <button
        type="button"
        role="tab"
        class="btn btn-primary text-nowrap"
        (click)="goTobuyChapters()"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{ "buymore:btn" | translate }}
      </button>
    </div>
  </div>
</div>
