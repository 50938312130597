import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { environment } from 'src/environments/environment';
import { Video } from 'src/app/interfaces/video/video';
import { ToastService } from 'src/app/services/toast.service';

declare var kWidget: any;

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, AfterViewInit, OnChanges {
  @Output() watched: EventEmitter<Video> = new EventEmitter();
  @Output() sendSeconds: EventEmitter<any> = new EventEmitter();
  @Input() video: Video | undefined;
  @Input() isMicroclip: boolean | undefined = false;
  @Input() getTime: any | undefined = {};

  loading = true;

  kdp: any | undefined;
  alreadyWatched = false;

  constructor(private toastService: ToastService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.getTime.start_time) {
      this.selectedInterval.start = this.getTime.start_time / 1000
      this.selectedInterval.end = this.getTime.end_time / 1000
    }
  }
  ngAfterViewInit(): void {
    let flashvars: any = { autoPlay: false };

    if (this.video?.source?.enable_watermark) {
      flashvars = {
        autoPlay: false,
        watermark: {
          plugin: true,
          img: '/assets/img/logos/mllogo-md.png',
          href: '',
          cssClass: this.video.source.watermark_position
            ? this.video.source.watermark_position.replace(
                /-([a-z])/g,
                function (g) {
                  return g[1].toUpperCase();
                }
              )
            : 'topRight',
        },
      };
    }

    let that = this;
    let interval = setInterval(() => {
      if (kWidget) {
        kWidget.embed({
          targetId: 'kaltura_player_' + this.video?.source?.external_id,
          wid: '_' + environment.vixyPId,
          uiconf_id: environment.vixyPlayerId,
          flashvars: flashvars,
          cache_st: this.video?.source?.external_id,
          entry_id: this.video?.source?.external_id,
          readyCallback: function readyCallback(playerID: any) {
            that.kdp = document.getElementById(playerID);

            that.kdp.kBind('monitorEvent', () => {
              that.updateTime(
                that.kdp.evaluate('{video.player.currentTime}'),
                that.kdp.evaluate('{duration}')
              );
            });
          },
        });
        clearInterval(interval);
      }
    }, 500);

    this.loading = false;
  }

  ngOnInit(): void {}

  updateTime(currentTime: any, duration: any) {
    currentTime = (currentTime * 1000).toFixed(0);
    duration = (duration * 1000).toFixed(0);

    if (duration) {
      var percentage = Math.trunc((currentTime * 100) / duration);

      if (percentage >= 96 && !this.alreadyWatched) {
        this.watched.emit(this.video);
        this.alreadyWatched = true;
      }
    }
  }

  public selectedInterval: any = { start: 0, end: 0 };

  setIntervalPoint(point: 'start' | 'end') {
    const currentTime = this.kdp.evaluate('{video.player.currentTime}');
    let tempInterval = { ...this.selectedInterval }; // Create a temporary copy for validation
    tempInterval[point] = currentTime;

    // Validation
    if (tempInterval.start && tempInterval.end) {
      if (tempInterval.start > tempInterval.end) {
        this.toastService.showError(
          'Start time cannot be greater than end time.'
        );
        return;
      } else if (tempInterval.end < tempInterval.start) {
        this.toastService.showError('End time cannot be less than start time.');
        return;
      }
    }

    // Assign validated values to the original variable
    this.selectedInterval[point] = currentTime;
    this.updateIntervalDisplay();
  }

  updateIntervalDisplay() {
    this.sendSeconds.emit(this.selectedInterval);
  }
}
