<div class="confirm-email py-3">
  <div class="container d-md-flex align-items-center">
    <div class="col-md-1 col-12 mx-2">
      <img
        src="assets/img/subscriptions/subscription.gif"
        class="the-image"
      /> 
    </div>
    <div class="col-md-8 col-12 mx-2">
      <p class="text-white fw-bold my-auto" >
        <span style="cursor:pointer;" (click)="openSubscriptionModal()">{{ "subscription:plan:title" | translate }}</span>
      </p>
    </div>
    <div  class="col-md-4 mx-2 text-md-end col-12 my-md-0 my-2">
      <a (click)="openSubscriptionModal()" class="btn btn-primary btn-subscribe">{{
        "subscription:plan:btn" | translate
      }}</a>
    </div>
     
  </div>
</div>
