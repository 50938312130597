import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionService } from 'src/app/services/collection.service';
 
@Component({
  selector: 'app-order-result',
  template: ''
})
export class OrderResultComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private cs: CollectionService,
 
    
    private router: Router
  ) { }

  ngOnInit(): void {
    let self=this
    this.route.queryParams.subscribe( (params) => {
      //let find the collection
      //console.log('params',params.collections)
      if (params.subs==1){
        //estoy ante el pago de una subscription       
        self.router.navigate(['/'], { queryParams: params });     
        return;
      }
      this.cs.getCollectionId(params.collections).then(res => {
       
          console.log('res.data',res.data)
          let data=res.data.collection
          if (data.type=='quest_level_4'){
            console.log('es nivel 4')
            self.router.navigate(['/dh/level-4'],{ queryParams: params });

          }else{
            console.log('es curso')

            self.router.navigate(['/courses', params.collections], { queryParams: params });

          }
  
        
        
      });

    })
  }
}
