import { Component, OnInit, Input } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/services/toast.service';
import { UsersService } from 'src/app/services/users.service';
import { DeactivateAcceptComponent } from '../../notify/deactivate-accept/deactivate-accept.component';
import { TranslateService } from '@ngx-translate/core';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-subcription-cancel-modal',
  templateUrl: './subscription-cancel-modal.component.html',
  styleUrls: ['./subscription-cancel-modal.component.scss']
})
export class subscriptionCancelModalComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() user: any = {};

  faTimes = faTimes;
  faCircleNotch = faCircleNotch;
  loading = false;

  constructor(
    private usersService:UsersService,
    private toastService:ToastService, 
    private translateService: TranslateService,
    ) { }

  ngOnInit(): void {
    // console.log(this.isASuperUser());
  }
  isASuperUser(){
    return (this.user.type == 'manager'); 
  }
  closeModal(){
    if(this.selfModal) this.selfModal?.close();
    else $("ngb-modal-window").click();
  }
  cancelPlan(){
    this.loading = true;
    
 

    this.usersService.cancelPlan(this.user.id)
        .then((res:any)=>{
          if(res.result == 'success'){
            this.closeModal();
            this.toastService.showSuccess(
              this.translateService.instant('subscription:cancel:plan')
            );
            window.location.reload();
            this.loading = false;
          }
          if(res.status == 422){
            this.toastService.showError(res.error.errors.message);
            this.loading = false;
          }
          if (res.status == 401){
            this.toastService.showError(res.error.message.friendly);
            this.loading = false;
          }          
        })
        .catch((err)=>{
          this.toastService.showError(err);
          this.loading = false;
        }) 
  }
}