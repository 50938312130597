import { LeaveCommentaryModalComponent } from './../../modals/group/leave-commentary-modal/leave-commentary-modal.component';
import { GroupsService } from './../../services/groups.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InitService } from 'src/app/services/init.service';
import { LangService } from 'src/app/services/lang.service';
import { faCircleNotch, faComment } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/services/toast.service';
import { ActivatedRoute } from '@angular/router';
import { CourseUtilitiesService } from 'src/app/utilities/course-utilities.service';
import { AuthService } from 'src/app/services/auth.service';
import { CreateConversationComponent } from 'src/app/modals/group/create-conversation/create-conversation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionsService } from 'src/app/utilities/permissions.service';

@Component({
  selector: 'app-conversation-card',
  templateUrl: './conversation-card.component.html',
  styleUrls: ['./conversation-card.component.scss'],
})
export class ConversationCardComponent implements OnInit {
  @Input() tab: string = '';
  @Input() conversation: any;
  @Input() showIcon: any = true;
  @Input() containerClass: any;
  @Output() refreshConversations: EventEmitter<any> = new EventEmitter();
  public groupId: any;
  public group: any;
  public showButton: boolean = false;
  userLanguage: string | null = '';
  faComment = faComment;

  faCircleNotch = faCircleNotch;
  public saveLoading: boolean = false;

  constructor(
    public auth: AuthService,
    private groupService: GroupsService,
    private initService: InitService,
    private lang: LangService,
    private translate: TranslateService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private ngbModal: NgbModal,
    private courseUtils: CourseUtilitiesService,
    private permissionsService: PermissionsService
  ) {
    this.lang.language.subscribe((lang) => {
      this.userLanguage = lang || 'en';
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const groupId = params.get('id');
      this.groupId = groupId;

      this.groupService.getProfile(this.groupId).then((res) => {
        this.group = res.data.collection;

        this.showButton =
          this.courseUtils.userIsOwner(this.group) ||
          this.auth.hasAnyRole(['moderator', 'super_user']);
      });
    });
  }

  public deleteConversation(conversation: any) {
    if (confirm('Are you sure you want delete the conversation?')) {
      this.saveLoading = true;
      this.groupService
        .deleteConversation(conversation.id)
        .then((resp) => {
          this.toastService.showSuccess(
            this.translate.instant('Conversation deleted')
          );
          this.saveLoading = false;
          this.refreshConversations.emit();
        })
        .catch((err: any) => {
          console.log(err);
          this.refreshConversations.emit();
          this.toastService.showError(
            this.translate.instant('Error deleting conversation')
          );
        });
    }
  }

  public editConversation(conversationId: number) {
    let modalRef = this.ngbModal.open(CreateConversationComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.conversationId = conversationId;
    modalRef.componentInstance.conversationData = this.conversation;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.refreshConversations.emit();
    });
  }

  public userCanEdit(conversation: any) {
    if (conversation) {
      return (
        this.permissionsService.userIsOwner(conversation) ||
        this.auth.hasAnyRole(['moderator', 'super_user'])
      );
    }
    return false;
  }

  public leaveCommentaryModal(conversation: any) {
    if (
      this.tab === 'conversations' ||
      this.tab === 'files' ||
      this.tab == 'activity'
    ) {
      let modalRef = this.ngbModal.open(LeaveCommentaryModalComponent, {
        windowClass: 'default-modal video-modal',
        size: '900',
        centered: true,
      });
      modalRef.componentInstance.conversationId = conversation.id;
      modalRef.componentInstance.typeConversation = conversation.mimetype
        ? 'file'
        : 'commentaryConversation';
      modalRef.componentInstance.countComment.subscribe(($event: any) => {
        this.conversation.count_comments = $event;
      });
    }
  }

  processLinks(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const links = doc.querySelectorAll('a');

    links.forEach((link) => {
      const originalUrl = link.href;
      if (originalUrl.length > 20) {
        const shortUrl = originalUrl.substring(0, 20) + '...';
        link.innerText = shortUrl; // Cambiar el texto del enlace
      }
    });

    return doc.body.innerHTML; // Actualizar el contenido procesado
  }
}
