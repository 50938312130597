<div class="table-responsive-sm mb-4">
  <table class="table table-hover" #container>
    <thead>
      <tr class="" [ngClass]="{ 'custom-border': true }">
        <th scope="col" class="my-3 align-middle px-4">
          <span>
            {{ "movielearning:admin:orders:table:title:1" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "movielearning:admin:orders:table:title:2" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "movielearning:admin:orders:table:title:3" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "movielearning:admin:orders:table:title:4" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "movielearning:admin:orders:table:title:7" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "movielearning:admin:orders:table:title:8" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "movielearning:admin:orders:table:title:5" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "movielearning:admin:orders:table:title:6" | translate }}
          </span>
        </th>
        <th class="text-center" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container>
        <tr
          [ngClass]="{ 'custom-border': true }"
          *ngFor="let item of listOrders; let i = index"
        >
          <td (click)="openDetail(item.id)" class="align-middle id-order px-4">
            #{{ item.id }}
          </td>
          <td *ngIf="item.type=='one_time'&&item.product_name!='teamroleprofile:checkout:product:name:teamrole:profile:analysis'&&item.product_name!='teamroleprofile:checkout:product:name:teamrole:profile'" class="title-course align-middle">
            {{ (item.product_name  )| excerpt : 45 }}
          </td>

          <td *ngIf="item.type=='one_time'&&item.product_name=='teamroleprofile:checkout:product:name:teamrole:profile:analysis'" class="title-course align-middle">
            {{ ('teamroleprofile:checkout:product:name:teamrole:profile:analysis:small'|translate)}}

          </td>
          <td *ngIf="item.type=='one_time'&&item.product_name=='teamroleprofile:checkout:product:name:teamrole:profile'" class="title-course align-middle">
            {{ ('teamroleprofile:checkout:product:name:teamrole:profile:small'|translate) }}
          </td>
        
          <td *ngIf="item.type=='subscription'" class="title-course align-middle">
            {{ (getPlanName(item)|translate) }}
          </td>
       
          <td class="align-middle item-table">
            {{ item?.completed_at | date : "dd LLL YYYY" }}
          </td>
          <td class="align-middle">
            {{ item?.user.email || "" }}
          </td>
          <td class="align-middle item-table">
            {{ getStartDate(item) | date : "dd LLL YYYY" }}
          </td>
          <td class="align-middle item-table">
            {{ item?.end_date | date : "dd LLL YYYY" }}
          </td>
          <td
            [ngClass]="{
              'custom-color': item.payment_status == 'paid',
              'custom-color2': item.payment_status == 'pending'
            }"
            class="align-middle"
          >
            {{ item.payment_status | titlecase }}
          </td>
          <td class="align-middle fw-bold">
            €{{ item?.total_taxed | number : "1.2-2" }}
          </td>
          <td colspan="3" class="align-middle text-center">
            <div
              ngbDropdown
              class="d-inline-block float_right"
              placement="bottom-right"
            >
              <button
                type="button"
                class="btn btn-outline-primary"
                id="dropdownBasic1"
                ngbDropdownToggle
              >
                <i class="fa fa-ellipsis-h"></i>
              </button>
              <div
                ngbDropdownMenu
                aria-labelledby="dropdownBasic1"
                class="mt-1"
              >
                <button (click)="openDetail(item.id)" ngbDropdownItem>
                  {{
                    "organization:settings:users:table:dropdown:item:1"
                      | translate
                  }}
                </button>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
