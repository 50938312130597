import { VideoDuplicateComponent } from './../video-duplicate/video-duplicate.component';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import {
  faPlus,
  faStar,
  faTimes,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Video } from 'src/app/interfaces/video/video';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { VideoService } from 'src/app/services/video.service';
import { OrganizationUtilitiesService } from 'src/app/utilities/organization-utilities.service';
import { VideoUtilitiesService } from 'src/app/utilities/video-utilities.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/services/toast.service';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { SearchUsersComponent } from 'src/app/shared/search-users/search-users.component';
import { CourseUtilitiesService } from 'src/app/utilities/course-utilities.service';
import { CollectionUtilitiesService } from 'src/app/utilities/collection-utilities.service';
import { AuthService } from 'src/app/services/auth.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ConfirmationDeleteComponent } from '../organizations/confirmationDelete/confirmation-delete/confirmation-delete.component';
import * as moment from 'moment';
import { LangService } from 'src/app/services/lang.service';
import { AddMicroclipComponent } from '../courses/add-microclip/add-microclip.component';
declare var kWidget: any;
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {
  @ViewChild(SearchUsersComponent) child: SearchUsersComponent | undefined;

  @Input() selfModal: NgbModalRef | null = null;
  @Input() videoId: number | null = null;
  @Input() type: string = '';
  @Input() group: number | null = null;
  @Input() playlistId: any = '';
  @Input() playlistIdEdit: any = '';
  @Input() fromPlaylistProfile: boolean = false;
  @Output() refreshDelete = new EventEmitter<any>();

  video: Video | undefined;
  video_exist: any = true;
  relatedVideo: any = [];
  currentLang = 'en';
  favoriteText = 'en';
  title = '';
  description = '';
  isCollapsed = true;
  isDHOrg = false;
  loading = true;
  loadingTags = true;
  loadingComments = true;
  loadingrelatedVideo = true;
  textGift = '';
  rating = 0;
  userRate = 0;
  votingActive = false;
  ratingList: Array<any> = [];
  giftLoading = false;
  faCircleNorch = faCircleNotch;
  copied = false;
  public videoFavorite: any = false;
  circleItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  phasesSelected: Array<Array<number>> = [];

  comments = [];
  dh_tags: Array<any> = [];
  tags: Array<any> = [];
  userTags: Array<any> = [];
  timeTags: Array<any> = [];
  selectUsers: Array<any> = [];

  faTimes = faTimes;
  faEye = faEye;
  faStar = faStar;
  faPlus = faPlus;
  faHeart = faHeart;
  public language = 'en';
  constructor(
    private lang: LangService,
    private videoService: VideoService,
    private videoUtils: VideoUtilitiesService,
    private orgService: OrganizationsService,
    private orgUtils: OrganizationUtilitiesService,
    private router: Router,
    private auth: AuthService,
    private toastService: ToastService,
    private translate: TranslateService,
    private collectionUtils: CollectionUtilitiesService,
    @Inject(DOCUMENT) private document: Document,
    private gas: GoogleAnalyticsService,
    private ngbModal: NgbModal
  ) {
    this.lang.language.subscribe((lang: any) => {
      this.language = lang || 'en';
      moment.locale(this.language);
    });
  }

  ngOnInit(): void {
    this.isDHOrg = this.orgService.getCurrent()?.dh_organization ? true : false;

    if (this.videoId) {
      this.videoService
        .getById(this.videoId)
        .then((response) => {
          this.video = response.data.video;
          this.video_exist = this.video.source?.exist;
          console.log(' this.video_exist', this.video_exist);
          if (!this.selfModal) {
            const orgId = this.orgService.getCurrent()?.id;

            const found = this.video.organizations?.find((o) => o.id == orgId);
            if (!found) {
              this.router.navigate(['/video']);
              this.toastService.showError('Video not found');
            }
          }

          this.videoFavorite = this.video?.stats?.relation?.starred;

          this.favoriteText = this.videoFavorite
            ? 'user:profile:tab:watchlist'
            : 'user:profile:tab:watchlist:no';

          let info = this.videoUtils.getInfoByLang(response.data.video);
          if (info) {
            this.title = info.name;
            this.description = info.description;
          }

          let flashvars = {};
          // console.log(this.video?.source?.length);
          if (this.video?.source?.enable_watermark) {
            flashvars = {
              watermark: {
                plugin: true,
                img: 'assets/img/logos/mllogo-md.png',
                href: '',
                cssClass: this.video.source.watermark_position
                  ? this.video.source.watermark_position.replace(
                      /-([a-z])/g,
                      function (g) {
                        return g[1].toUpperCase();
                      }
                    )
                  : 'topRight',
              },
            };
          }

          this.rating = this.video.ratings.starsAvg
            ? this.video.ratings.starsAvg
            : 0;
          this.ratingList = this.video.ratings_list!;
          this.userRate = this.video.ratings.loggedUserRating
            ? this.video.ratings.loggedUserRating
            : 0;
          this.dh_tags = this.videoUtils.getDhTags(this.video);

          this.dh_tags.forEach((dh_tag, i) => {
            dh_tag.phases_selected.forEach((value: number) => {
              if (!this.phasesSelected[i]) {
                this.phasesSelected[i] = [];
              }
              if (value === 13) {
                this.phasesSelected[i].push(1);
              } else {
                this.phasesSelected[i].push(value);
              }
            });
          });

          this.videoService
            .getRelatedVideo(this.videoId || 0)
            .then((response) => {
              let obj = response.video.related_videos.result;
              let obj2: any = [];
              let i = 0;
              obj.forEach(function (element: any) {
                let o = {
                  type: 'video',
                  content: element,
                };
                obj2[i] = o;
                i++;
              });

              this.relatedVideo = obj2;
              this.loadingrelatedVideo = false;
              console.log('this.relatedVideo');
              console.log(this.relatedVideo);
            });

          this.videoService.getTags(this.videoId || 0).then((response) => {
            this.tags = response.data.video.analysis.tags;

            this.userTags = this.tags.filter((tag) => {
              return tag.tag_type == 'user';
            });

            this.timeTags = this.tags.filter((tag) => {
              return tag.in !== null && tag.in !== null;
            });

            this.tags = this.tags.filter((tag) => {
              return tag.tag_type == 'doublehealix';
            });

            this.loadingTags = false;
          });

          this.loading = false;

          setTimeout(() => {
            kWidget?.embed({
              targetId: 'kaltura_player_' + this.video?.source?.external_id,
              wid: '_' + environment.vixyPId,
              uiconf_id: environment.vixyPlayerId,
              flashvars: flashvars,
              cache_st: this.video?.source?.external_id,
              entry_id: this.video?.source?.external_id,
            });
          }, 15);
          this.markvideo();

          if (this.auth.isAuthenticated()) {
            this.gas.set('metric1', this.auth.getMe()?.id);
            this.gas.set('dimension1', this.auth.getMe()?.id);
            this.gas.set('metric2', this.video.id);
            this.gas.event(
              'video',
              'user_interaction',
              this.title,
              this.video.id,
              false
            );
          }
        })
        .catch((error) => {
          if (error.status == 404) {
            this.router.navigate(['/video']);
          }
          this.toastService.showError(error.error.message.friendly);
        });
    }
  }

  toggleVoting() {
    this.votingActive = !this.votingActive;
  }

  rate(stars: number) {
    this.videoService
      .rate(this.video?.id!, stars)
      .then((data) => {
        this.userRate = stars;
        this.rating = data.starsAvg;
        this.toastService.showSuccess('Rating saved');
      })
      .catch((err) => {
        this.toastService.showError('Error on rating');
      })
      .finally(() => {
        this.votingActive = false;
      });
  }

  isPhaseSelected(dh_tag_i: number, circleItem: number) {
    return this.phasesSelected[dh_tag_i].indexOf(circleItem) !== -1;
  }

  isPublicOrg(): boolean {
    return this.orgUtils.isPublic();
  }

  copyLink() {
    this.copied = true;
    let url =
      this.document.location.protocol +
      '//' +
      this.document.location.hostname +
      (this.document.location.port == '4200'
        ? ':' + this.document.location.port
        : '');

    if (this.fromPlaylistProfile) {
      url += '/playlists/' + this.playlistId + '?videoId=' + this.videoId;
    } else {
      url += '/video/' + this.videoId;
    }
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    setTimeout(() => {
      this.copied = false;
    }, 3000);
  }

  select(data: any) {
    this.selectUsers = data;
    console.log('this.selectUsers');
    console.log(this.selectUsers);
  }
  openButton() {
    if (this.isPublicOrg()) {
      this.toastService.showError(
        'This feature is not accessible on this platform'
      );
    } else {
      this.isCollapsed = !this.isCollapsed;
    }
  }
  cancelButton() {
    this.isCollapsed = true;
    this.child?.reset();
    this.textGift = '';
  }
  sendGift() {
    let usersId: Array<any> = [];
    this.selectUsers.forEach((element) => {
      usersId.push(element.id);
    });

    let param = {
      message: this.textGift,
      user_ids: usersId,
    };
    this.giftLoading = true;

    if (this.video?.id) {
      this.videoService
        .sendGift(this.video?.id, param)
        .then((res) => {
          if (this.selectUsers.length == 1) {
            this.toastService.showSuccess(
              this.translate.instant(`play_video:video_gift:invite:success`) +
                ` ${this.selectUsers[0].username}`
            );
          } else {
            this.toastService.showSuccess(
              this.translate.instant(
                `play_video:video_gift:invite:success:many_people`
              )
            );
          }
          this.giftLoading = false;
          this.child?.reset();
          this.textGift = '';
          this.isCollapsed = true;
        })
        .catch((err) => {
          console.log(err);
          this.giftLoading = false;
          this.toastService.showError(
            this.translate.instant('play_video:video_gift:invite:error')
          );
        });
    }
  }

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  public markvideo() {
    this.videoService.markView(this.videoId).then((resp) => {
      console.log(resp);
    });
  }

  public goEdit() {
    this.closeModal();

    if (this.playlistId) {
      this.router.navigate(['/video/edit', this.video?.id], {
        queryParams: { group: this.group, playlist_id: this.playlistId },
      });
    } else {
      if (this.playlistIdEdit) {
        this.router.navigate(['/video/edit', this.video?.id], {
          queryParams: { playlistEdit_id: this.playlistIdEdit },
        });
      } else {
        this.router.navigate(['/video/edit', this.video?.id], {
          queryParams: { group: this.group },
        });
      }
    }
  }

  public addFavorite() {
    if (!this.videoFavorite) {
      this.videoService.addFavorite(this.videoId).then((resp) => {
        if (resp.result == 'success') {
          this.videoFavorite = true;
          this.favoriteText = 'user:profile:tab:watchlist';
          this.toastService.showSuccess(
            this.translate.instant(`user:profile:tab:watchlist:success`)
          );
        } else {
          this.toastService.showError(
            this.translate.instant('user:profile:tab:watchlist:error')
          );
        }
      });
    } else {
      this.videoService.removeFavorite(this.videoId).then((data: any) => {
        if (data.result == 'success') {
          this.videoFavorite = false;
          this.favoriteText = 'user:profile:tab:watchlist:no';
          this.toastService.showSuccess(
            this.translate.instant(`user:profile:tab:watchlist:no:success`)
          );
        } else {
          this.toastService.showError(
            this.translate.instant('user:profile:tab:watchlist:error')
          );
        }
      });
    }
  }
  UserCanEdit() {
    return this.collectionUtils.userCanEdit(this.video);
  }

  duplicateVideo() {
    this.closeModal();
    let modalRef = this.ngbModal.open(VideoDuplicateComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
    });
    modalRef.componentInstance.video = this.video;
  }

  public deleteVideModal(videoId: any) {
    let modalRef = this.ngbModal.open(ConfirmationDeleteComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.refreshDelete.subscribe((data: any) => {
      this.deleteVideo(videoId);
    });
  }

  public deleteVideo(videoId: number) {
    this.videoService
      .deleteVideo(videoId)
      .then((result) => {
        this.refreshDelete.emit();
      })
      .catch((err) => {});
  }
  public openModalMicroclip() {
    this.closeModal();
    let modalRef = this.ngbModal.open(AddMicroclipComponent, {
      windowClass: 'default-modal',
      size: '1000',
      centered: false,
    });
    modalRef.componentInstance.fromVideo = true;
    modalRef.componentInstance.clipFromVideo = this.video;
    // modalRef.componentInstance.infoMicroclip.subscribe((microclip: any) => {
    //   this.videoId = microclip.video_post_id;

    //   this.arrayOfContent.splice(index + 1, 0, {
    //     position: this.arrayOfContent - 1,
    //     show_description: true,
    //     type: 'microclip',
    //     microclip_id: microclip.id,
    //     video_id: microclip.video_post_id,
    //     info: microclip,
    //   });

    //   this.coursesService.getFlavors(microclip.video_post_id).then((response) => {
    //     this.video = response.data.video;
    //   });
    // });
  }
}
