import { EditMemberComponent } from './../../modals/organizations/edit-member/edit-member.component';
import { DeactivateOneMemberComponent } from './../../modals/organizations/deactivate-one-member/deactivate-one-member.component';
import { ConfirmationDeleteComponent } from './../../modals/organizations/confirmationDelete/confirmation-delete/confirmation-delete.component';
import { DeleteInvitationComponent } from './../../modals/delete-invitation/delete-invitation.component';
import { ResendInvitationComponent } from './../../modals/resend-invitation/resend-invitation.component';

import { ToastService } from 'src/app/services/toast.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { EnrolledStatsComponent } from 'src/app/modals/organizations/courses/enrolled-stats/enrolled-stats.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-table-organization',
  templateUrl: './table-organization.component.html',
  styleUrls: ['./table-organization.component.scss'],
})
export class TableOrganizationComponent implements OnInit {
  @Output() scrolled = new EventEmitter<null>();
  @Output() refreshTable = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();
  @Output() goLoading = new EventEmitter<any>();
  @Output() goLoadingMember = new EventEmitter<any>();

  @ViewChild('container') container!: ElementRef;
  @Output() membersSelected = new EventEmitter<any>();
  @Input() headers: any;
  @Input() listVideos: any[] = [];
  @Input() listMembers: any[] = [];
  @Input() listCourses: any[] = [];
  @Input() type: string = '';
  @Input() hideCheck: boolean = true;
  @Input() deselectMembers: boolean = false;
  @Input() currentTab: string = 'allMembers';
  public memberSelected: any[] = [];
  public organizationId: any = '';
  public dataNeeded: any = {};
  faStar = faStar;

  constructor(
    private organizationService: OrganizationsService,
    private toastService: ToastService,
    private ngbModal: NgbModal,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.organizationId = this.organizationService.organizationId;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.deselectMembers) {
      this.refreshSelected();
    }
  }

  public refreshSelected() {
    this.memberSelected = [];
  }

  public write(member: any, item: any) {
    if (item.nickname != 'icon') {
      if (member[item.nickname] == 'pending_by_user') {
        return 'Invited';
      } else {
        if (member[item.nickname] == 'accepted') {
          return 'Active';
        } else {
          return member[item.nickname];
        }
      }
    }
    return null;
  }

  public checkAllCheckBox(event: any) {
    this.listMembers.forEach((x: { checked: any }) => {
      x.checked = event.target.checked;
      this.selectMember(x);
    });

    this.listVideos.forEach(
      (x: { checked: any }) => (x.checked = event.target.checked)
    );
  }

  public selectMember(member: any) {
    if (member.checked) {
      this.dataNeeded[member.user_id || member.origin_id] = member.user || {
        name: member.name,
        email: member.email,
      };
    } else {
      delete this.dataNeeded[member.user_id || member.origin_id];
    }
    this.membersSelected.emit(this.dataNeeded);
  }

  public deleteVideo(videoId: any, index: number) {
    this.organizationService
      .deleteVideoOrganization(this.organizationId, videoId)
      .then((resp) => {
        console.log(resp);

        if (resp.result == 'error') {
          this.toastService.showError(resp.data.message.friendly);
        } else {
          this.listVideos.splice(index, 1);
          this.toastService.showSuccess('Video Deleted');
        }
      })
      .catch((error) => {
        this.toastService.showError(error);
      });
  }

  public deleteVideModal(videoId: any, index: number) {
    let modalRef = this.ngbModal.open(ConfirmationDeleteComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.refreshDelete.subscribe((data: any) => {
      this.deleteVideo(videoId, index);
    });
  }
  public editVideo(videoId: any, index: number) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(e: any) {
    let bottomPosition =
      this.container.nativeElement.getBoundingClientRect().top +
      this.container.nativeElement.offsetHeight;
    if (bottomPosition < window.innerHeight) {
      this.scrolled.emit();
    }
  }

  public orderByColumn(name: string) {
    if (name == 'Created') {
      this.refreshTable.emit('created_at');
    }
    if (name == 'Name') {
      this.refreshTable.emit('name');
    }
    if (name == 'Last Played') {
      this.refreshTable.emit('last_view');
    }
  }

  public openModalView(course: any) {
    let modalRef = this.ngbModal.open(EnrolledStatsComponent, {
      windowClass: 'default-modal',
      size: '1000',
      centered: false,
    });
    modalRef.componentInstance.course = course;
    modalRef.componentInstance.refresh.subscribe((data: any) => {});
  }

  public goToCourse(course: any) {
    console.log(course);

    this.router.navigate([
      '/courses',
      course.id,
      'cursus',
      course.name.split(' ').join('-'),
    ]);
  }

  public removeFromPlatform(memberId: string) {
    this.organizationService
      .removeFromPlatform(this.organizationId, memberId)
      .then((resp: any) => {
        if (resp.result == 'success') {
          this.toastService.showSuccess('Removed');
          this.refresh.emit();
        } else {
          this.toastService.showError('Error removing member');
        }
      });
  }

  public changeRole(memberId: string, index: number, type: string) {
    this.organizationService
      .makeModerator(this.organizationId, memberId, type)
      .then((resp) => {
        if (resp.result == 'success') {
          if (type == 'team_trainer') {
            type = 'Trainer';
          }
          this.listMembers[index].type = type;
          this.toastService.showSuccess('Role changed');
        } else {
          this.toastService.showError('Error');
        }
      })
      .catch((error) => {
        this.toastService.showError(error);
      });
  }

  public resetPassword(email: string) {
    this.organizationService
      .resetPassword(email)
      .then((resp) => {
        if (resp.result == 'success') {
          this.toastService.showSuccess(
            'This user will receive an email to reset his/her password'
          );
        } else {
          this.toastService.showError('Error');
        }
      })
      .catch((error) => {
        this.toastService.showError(error);
      });
  }

  public deactivateMember(
    memberId: string,
    index: number,
    activate: boolean = false
  ) {
    let modalRef = this.ngbModal.open(DeactivateOneMemberComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: false,
    });
    modalRef.componentInstance.memberId = memberId;
    modalRef.componentInstance.activate = activate;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.listMembers[index].user.account_deactivated = !data;
    });
  }

  public goToDelete(memberId: string, member: string) {
    this.router.navigate(['/delete-account', memberId, member]);
  }
  public goToResendInvitation(member: any) {
    let modalRef = this.ngbModal.open(ResendInvitationComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.user = member.name;
    modalRef.componentInstance.email = member.email;
    modalRef.componentInstance.invitedId = member.origin_id;
    modalRef.componentInstance.organizationId = this.organizationId;
    modalRef.componentInstance.invitie = member.message;

    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.goLoading.emit();
    });
  }

  public goToDeleteInvitation(member: any) {
    console.log('delete', member);
    let modalRef = this.ngbModal.open(DeleteInvitationComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.member = member;
    modalRef.componentInstance.organizationId = this.organizationId;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.goLoading.emit();
    });
  }

  public openEdit(member: any) {
    let modalRef = this.ngbModal.open(EditMemberComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.member = member;
    modalRef.componentInstance.organizationId = this.organizationId;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.goLoadingMember.emit();
    });
  }
  hasRole(role:string){
  //  console.log('test',this.auth.hasOrganizationRole(role));
    return this.auth.isAdmin();
  }
  hasRoles(roles: Array<string>) {

    return this.auth.hasAnyRole(roles);
  }
}
