<div class="modal-close" (click)="closeModal()">
  <i class="fas fa-times me-1"></i><span>{{ "btn:close" | translate }}</span>
</div>

<div class="modal-header">
  <h4 class="modal-title">
    {{ "course:buy:" + status + ":title" | translate }}
  </h4>
</div>

<div class="modal-body">
  <div *ngIf="loading; else content" class="row p-5">
    <div class="justify-content-center">
      <app-loading-icon></app-loading-icon>
    </div>
  </div>

  <ng-template #content>
    <div class="course-image rounded mb-3">
      <img
        class="w-100"
         src="assets/img/logos/mllogo.png" 
        alt=""
      />
    </div>
    <div class="row text-center py-2">
      <p>
        {{ "course:buy:" + status + ":description" | translate }}
        <strong *ngIf="status == 'paid'">{{ order!.user!.email }}</strong
        ><span *ngIf="status == 'paid'">.</span>
        <span *ngIf="status == 'cancelled'"
          ><strong>contact@movielearning.com</strong>.</span
        >
      </p>
    </div>

    <div *ngIf="status == 'paid'">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:1" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{
                "course:buy:payment:method:" +
                  (order!.payment_method || order!.method) | translate
              }}</strong>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:2" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>  {{order!.payments![0].id}}</strong>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:3" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{ order!.id }}</strong>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:4" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong
                >{{(getOrderDate()) | titlecase }} CET
                timezone</strong
              >
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:5" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{
                invoiceDetails!.firstname + " " + invoiceDetails!.lastname
              }}</strong>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:6" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{
                (invoiceDetails!.is_company
                  ? "course:buy:paymentInfoList:item:6:business"
                  : "course:buy:paymentInfoList:item:6:user"
                ) | translate
              }}</strong>
            </div>
          </div>

          <div class="row mt-2" *ngIf="invoiceDetails!.country_code">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:11" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{ invoiceDetails!.country_code }}</strong>
            </div>
          </div>

          <div class="row mt-2" *ngIf="invoiceDetails!.is_company">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:7" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{ invoiceDetails!.company_name }}</strong>
            </div>
          </div>

          <div class="row mt-2" *ngIf="invoiceDetails!.is_company">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:8" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{ invoiceDetails!.company_address }}</strong>
            </div>
          </div>

          <div class="row mt-2" *ngIf="invoiceDetails!.is_company">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:9" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{ invoiceDetails!.company_vat }}</strong>
            </div>
          </div>

          <div class="row mt-2" *ngIf="invoiceDetails!.is_company">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:10" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{ invoiceDetails!.coc }}</strong>
            </div>
          </div>
        </div>
      </div>

      <h3 class="h5 mt-4">
        {{ "course:buy:payment:order:title" | translate }}
      </h3>

      <div class="card">
        <div class="card-body">
          <div class="row justify-content-end mt-2">
            <div class="col-3">
              <img
                class="img-course"
                [src]="
                  competencies?.length > 0
                    ? 'assets/img/dh-profile/img-card3.png'
                    : 'assets/img/dh-profile/img-card3.png'
                "
              />
            </div>
            <div class="col col-md-9">
 
             
                <div class="row">
                  <div class="col-auto">
                    <h6>{{ ("subscription:checkout:modal:text:1:" +order!.plan.key) |translate }}</h6>
                  </div>

                  <div class="col text-end">
                    <h6>
                      {{
                        order!.plan.price!
                          | currency : "EUR" : "symbol" : "1.0-2"
                      }}
                    </h6>
                  </div>
                </div>
 


                <p class="fs-14">
                  {{ ('subscription:checkout:modal:text:1:preview:'+ order!.plan.key)| translate }}
                  -
                  {{ 'subscription:checkout:modal:text:2:preview:duration' | translate: {
                    days: order!.plan.interval
                  
                  } }}
                </p>
             
            </div>
          </div>
          <hr />
          
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div class="modal-footer">
  <div class="container">
    <div class="row justify-content-end">
      <div class="col-6 text-end">
        <button
          
          class="btn btn-primary"
          (click)="closeModal()"
        >
          {{ "course:close:btn" | translate }}
        </button>
        <!-- <button *ngIf="!(order?.gift_user_id!=null||order?.email!=null)" class="btn btn-primary" (click)="goToCourse()">{{ 'course:start:btn' | translate }}</button> -->
      </div>
    </div>
  </div>
</div>
