import { Injectable } from '@angular/core';
import { User } from '../interfaces/user';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class subscriptionService {
  user: User | null = null

  constructor(
    private api: ApiService,
    private auth: AuthService
  ) {
    this.auth.user.subscribe( user => {
      this.user = user
    })
  }
  

  async getOrderSubscription(
    id: number,
   
  ) {
    try {
      
      const colletion = await this.api.get('/subscription/order/' + id);
      return colletion;
    } catch (error) {
      return error;
    }
  }
   
}
