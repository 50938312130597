<div
  style="padding-bottom: 60.11%; position: relative"
  *ngIf="this.video?.source?.exist"
>
  <div
    [id]="'kaltura_player_' + this.video?.source?.external_id"
    style="position: absolute; width: 100%; height: 100%"
  >
    <app-loading-icon *ngIf="loading"></app-loading-icon>
    <div *ngIf="!video" class="row text-center my-5">
      <h5>Video not found</h5>
    </div>
  </div>
</div>

<div
  *ngIf="!this.video?.source?.exist"
  class="d-flex align-items-center justify-content-center"
>
  <img
    src="assets/img/courses/video_not_found.png"
    alt="Imagen centrada"
    class="img-fluid"
    style="height: 400px"
  />
</div>

<ng-container *ngIf="isMicroclip">
  <div class="row justify-content-center">
    <div class="col-auto">
      <button
        id="setStartBtn"
        (click)="setIntervalPoint('start')"
        class="btn btn-primary my-3 mx-5"
      >
       {{'Set microclip start'}}
      </button>
      <input
        [(ngModel)]="selectedInterval.start"
        class="input-number form-control"
        id="clip-title"
        name="name"
        placeholder="{{
          'admin:users:modal:discountProfile:label:2:placeholder' | translate
        }}"
        type="number"
      />
    </div>
    <div class="col-auto">
      <button
        id="setEndBtn"
        (click)="setIntervalPoint('end')"
        class="btn btn-primary my-3 mx-5"
      >
        {{'Set microclip end'}}
      </button>
      <input
        [(ngModel)]="selectedInterval.end"
        class="input-number form-control"
        id="clip-title"
        name="name"
        placeholder="{{
          'admin:users:modal:discountProfile:label:2:placeholder' | translate
        }}"
        type="number"
      />
    </div>
  </div>
  <div class="row text-center my-2">
    <div id="selectedInterval">
      {{ "Selected interval: " | translate }}
      {{ this.selectedInterval.start.toFixed(2) }}s -
      {{ this.selectedInterval.end.toFixed(2) }}s
    </div>
  </div>
</ng-container>
