import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Paginator } from 'src/app/interfaces/paginator';
import { SearchSectionService } from 'src/app/services/search-section.service';
import { ToastService } from 'src/app/services/toast.service';
import { VideoService } from 'src/app/services/video.service';
import { environment } from 'src/environments/environment';
import { SimpleVideoComponent } from '../../simple-video/simple-video.component';
declare var kWidget: any, $: any;

@Component({
  selector: 'app-add-microclip',
  templateUrl: './add-microclip.component.html',
  styleUrls: ['./add-microclip.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [style({ display: 'none' })]),
    ]),
  ],
})
export class AddMicroclipComponent implements OnInit, OnChanges {
  @Output() infoMicroclip: EventEmitter<any> = new EventEmitter();
  @Input() selfModal: NgbModalRef | null = null;
  @Input() course: any = {};
  @Input() chapterId: any = {};
  @Input() title: String = '';
  @Input() fromVideo: boolean = false;
  @Input() editing: boolean = false;
  @Input() clipFromVideo: any = {};

  public loadingVideos: boolean = false;
  public searchText: string = '';
  public faCircleNorch: any = faCircleNotch;
  public paginatorVideos: any = {
    pages: 1,
    current_page: 0,
    per_page: 20,
    total: 0,
  };
  public videos: any = [];
  public searchTextOwn: string = '';
  public titleModal: string = 'course:create:microclip:title';
  public totalVideos: number = 0;
  public videoSelected: any = null;
  public video: any = null;
  public microclipId: any = null;
  public addingMicroclip: boolean = false;
  public sendingVideo: boolean = false;
  public loadingAddMicrolclip: boolean = false;
  public loadingDelete: boolean = false;
  faTimes = faTimes;

  public newMicroclip: any = {
    title: '',
    description: '',
    start_time: 0,
    end_time: 0,
    video_post_id: '',
  };
  public interval: any = {
    start_time: 0,
    end_time: 0,
  };

  constructor(
    private searchService: SearchSectionService,
    private toastService: ToastService,
    private videoService: VideoService,
    private translate: TranslateService,
    private ngbModal: NgbModal
  ) {}

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {
    if (this.fromVideo) {
      this.selectVideo(this.clipFromVideo);
    }
  }
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  timer: any;
  delaySearch = false;

  public searchOwn(button = false) {
    this.addingMicroclip = false;
    this.delaySearch = false;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (button) {
      this.paginatorVideos.current_page = 0;

      this.videos = [];
      if (!this.isEmpty(this.searchText)) {
        this.getMoreVideos();
      }
    } else {
      this.timer = setTimeout(() => {
        this.paginatorVideos.current_page = 0;

        this.videos = [];
        if (!this.isEmpty(this.searchText)) {
          this.getMoreVideos();
        }
      }, 1000);
    }
  }

  public isEmpty(str: string) {
    return !str.trim().length;
  }
  getMoreVideos() {
    if (
      !this.loadingVideos &&
      this.paginatorVideos.current_page <= this.paginatorVideos.pages
    ) {
      this.loadingVideos = true;
      this.searchService
        .searchMicroclip(
          this.searchText,
          null,
          null,
          null,
          null,
          null,
          this.paginatorVideos.per_page,
          parseInt(this.paginatorVideos.current_page) + 1
        )
        .then((resp) => {
          this.suggestions = resp.data.videos;

          this.paginatorVideos = resp.paginator;
          this.totalVideos = resp.paginator.total;

          this.delaySearch = true;
          this.loadingVideos = false;
        });
    }
  }

  suggestions: any[] = []; // Ejemplo de sugerencias

  filteredSuggestions: string[] = [];

  onKeyUp(event: KeyboardEvent) {
    this.getMoreVideos();
  }

  filterSuggestions() {
    this.filteredSuggestions = this.suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.suggestions-list') && !target.closest('.search')) {
      this.suggestions = [];
    }
  }
  selectVideo(video: any) {
    this.videoSelected = video;
    this.suggestions = [];
    this.searchText = '';
  }

  createMicroclip() {
    this.loadingVideos = true;
    if (this.videoSelected.id) {
      this.videoService
        .getById(this.videoSelected.id)
        .then((response) => {
          this.video = response.data.video;
          this.addingMicroclip = true;
          this.loadingVideos = false;
          this.newMicroclip = {
            title: '',
            description: '',
            start_time: 0,
            end_time: 0,
            video_post_id: '',
          };
        })
        .catch((error) => {
          console.error('Error al obtener el video:', error);
          this.loadingVideos = false;
          this.addingMicroclip = false;
        });
    }
    let flashvars = {
      'mediaProxy.mediaPlayFrom': this.newMicroclip.start_time,
      'mediaProxy.mediaPlayTo': this.newMicroclip.end_time,
    };
  }

  create() {
    this.sendingVideo = true;
    if (!this.editing) {
      this.newMicroclip['video_post_id'] = this.videoSelected.id;
    }

    if (!this.newMicroclip?.title || this.newMicroclip?.title.trim() === '') {
      this.toastService.showError(
        this.translate.instant('form:error:required', { field: 'title' })
      );
      this.sendingVideo = false;
      return;
    }
    if (this.editing) {
      this.searchService
        .editMicroclip(this.newMicroclip, this.microclipId)
        .then((resp) => {
          this.addingMicroclip = false;
          this.editing = false;
          this.sendingVideo = false;
          this.toastService.showSuccess(
            this.translate.instant('editing:microclip:video')
          );

          // Encuentra el índice del elemento que deseas reemplazar
          const index = this.videoSelected.microclips.findIndex(
            (microclip: any) => microclip.id === resp.data.id
          );

          // Si el elemento existe, reemplázalo
          if (index !== -1) {
            this.videoSelected.microclips[index] = resp.data;
          }
        })
        .catch((error) => {
          this.toastService.showError('Error');
          console.error('Error', error);
          this.loadingVideos = false;
          this.editing = false;

          this.addingMicroclip = false;
        });
    } else {
      this.searchService
        .createMicroclip(this.newMicroclip)
        .then((resp) => {
          this.addingMicroclip = false;
          this.sendingVideo = false;
          this.toastService.showSuccess(
            this.translate.instant('adding:microclip:video')
          );

          this.videoSelected.microclips.unshift(resp.data);
        })
        .catch((error) => {
          this.toastService.showError('Error');
          console.error('Error', error);
          this.loadingVideos = false;
          this.addingMicroclip = false;
        });
    }
  }

  embedVideo() {}

  getSeconds($event: any) {
    const startInSeconds = Number($event.start);
    const endInSeconds = Number($event.end);

    if (isNaN(startInSeconds) || isNaN(endInSeconds)) {
      console.error('Start time and end time must be numbers.');
      return;
    }

    this.newMicroclip['start_time'] = Number(startInSeconds * 1000); // Convert start time to milliseconds
    this.newMicroclip['end_time'] = Number(endInSeconds * 1000); // Convert end time to milliseconds
  }

  addToCourse(microclip: any) {
    this.loadingAddMicrolclip = true;

    // if (this.chapterId) {
    //   this.videoService
    //     .addMicroclipToCourse(this.chapterId, microclip)
    //     .then((response) => {
    //       this.toastService.showSuccess(
    //         this.translate.instant('adding:microclip:course')
    //       );
    //       this.addingMicroclip = false;
    //       this.loadingAddMicrolclip = false;
    //       this.infoMicroclip.emit(microclip);
    //     })
    //     .catch((error) => {
    //       console.error('Error:', error);
    //       this.loadingAddMicrolclip = false;
    //     });
    // } else {
    // }
    this.infoMicroclip.emit(microclip);
    this.toastService.showSuccess(
      this.translate.instant('adding:microclip:course')
    );
    this.loadingAddMicrolclip = false;
  }

  openVideo(video: any) {
    // let modalRef = this.ngbModal.open(SimpleVideoComponent, {
    //   windowClass: 'default-modal video-modal',
    //   size: 'lg',
    // });
    // modalRef.componentInstance.videoId = video?.video_post_id;
    // modalRef.componentInstance.selfModal = modalRef;
  }
  cancel() {
    this.addingMicroclip = false;
    this.editing = false;
  }

  editingMicroclip(microclip: any) {
    this.addingMicroclip = true;
    this.editing = true;
    this.loadingVideos = true;
    if (this.videoSelected.id) {
      this.videoService
        .getById(this.videoSelected.id)
        .then((response) => {
          this.video = response.data.video;
          this.loadingVideos = false;
          this.microclipId = microclip.id;
          this.newMicroclip = {
            title: microclip.title,
            description: microclip.description,
            start_time: microclip.start_time,
            end_time: microclip.end_time,
          };
          this.interval.start_time = microclip.start_time;
          this.interval.end_time = microclip.end_time;
        })
        .catch((error) => {
          console.error('Error al obtener el video:', error);
          this.loadingVideos = false;
          this.addingMicroclip = false;
        });
    }
  }

  deleteMicroclip(microclip: any, position: number) {
    this.videoSelected.microclips.forEach((item: any) => {
      item.loadingDelete = false; // Inicializas el valor como false, o el que necesites
    });
    this.videoSelected.microclips[position].loadingDelete = true;

    this.searchService
      .deleteMicroclip(microclip.id)
      .then((resp) => {
        this.videoSelected.microclips[position].loadingDelete = false;
        this.toastService.showSuccess(
          this.translate.instant('delete:microclip')
        );

        this.videoSelected.microclips.splice(position, 1);
      })
      .catch((error) => {
        this.toastService.showError('Error');
        this.loadingDelete = false;
      });
  }
}
