

<h5 class="mt-4">{{ 'discount:course:coupon:review' | translate }}</h5>
 
<div class="card">
  <div class="card-body">
    <div class="row justify-content-end mt-2">
      <div class="col-3">
        <img
          
              src="assets/img/logos/logo-square.png"
             
              alt="img"
              class="img-course"
              onerror="this.src='assets/img/groups/icons/list.png'"
            />
      </div>
      <div class="col col-md-9">
        <div class="row">
          <div class="col-auto">
            <h6>{{ this.checkout.course?.name }}</h6>
          </div>

          <div class="col text-end">
            <h6>{{ this.checkout.subscription?.subscription_days == this.checkout.course?.summaryOrders?.subscription_day_selected ? (this.checkout?.subscription?.price | currency:'EUR':'symbol':'1.2-2') : this.checkout.subscription?.originalPrice ? (this.checkout.subscription?.originalPrice | currency:'EUR':'symbol':'1.2-2') : (this.checkout.subscription?.price | currency:'EUR':'symbol':'1.2-2') }}</h6>
          </div>
        </div>

        <p class="fs-14" *ngIf="!this.checkout.isGift">
          {{ ('subscription:checkout:modal:text:1:preview:'+this.checkout.subscription?.key)| translate }}
          -
          {{ 'subscription:checkout:modal:text:2:preview:duration' | translate: {
            days: this.checkout.subscription?.subscription_days
          
          } }}
        </p>
      </div>
    </div>
    <hr>
    <div class="row justify-content-end mt-2">
      <div class="col col-md-6">
        <div class="row mb-2" *ngIf="this.checkout.discount">
          <div class="col fs-14">{{ "discount:choose:coupon:applied" | translate }}</div>
          <div class="col text-end fw-bold">{{ this.checkout.discount!.code }}</div>
        </div>
        <div class="row mb-2" *ngIf="this.checkout.discount">
          <div class="col fs-14">{{ "discount:choose:coupon:discount" | translate }}</div>
          <div class="col text-end fw-bold">{{ getTotalDiscount()| currency:'EUR':'symbol':'1.2-2' }}</div>
        </div>
        <div class="row mb-2">
          <div class="col fs-14">{{ "movielearning:admin:orders:profile:details:list:1" | translate }}</div>
          <div class="col text-end fw-bold">{{getNetValue() | currency:'EUR':'symbol':'1.2-2' }}</div>
          <!-- <div class="col text-end fw-bold">{{ getPriceWithDiscount(this.checkout.order!.in_total) | currency:'EUR':'symbol':'1.0-2' }}</div> -->
        </div>
        <div class="row mb-2">
          <div class="col fs-14">{{ "movielearning:admin:orders:profile:details:list:3" | translate:{tax: this.checkout.order!.tax_percentage * 100 || getTaxBycountry() * 100} }}</div>
          <div class="col text-end fw-bold">{{ getTaxValue() | currency:'EUR':'symbol':'1.2-2' }}</div>
        </div>
        <div class="row">
          <div class="col fs-14">{{ "movielearning:admin:orders:profile:details:list:4" | translate }}</div>
          
          
          <div class="col text-end fw-bold"> {{  getTotalValue() | currency:'EUR':'symbol':'1.2-2' }}</div>
          <!-- <div class="col text-end fw-bold">{{ this.checkout.subscription!.subscription_days == this.checkout.course!.summaryOrders.subscription_day_selected ? (this.checkout.subscription!.price | currency:'EUR':'symbol':'1.0-2') : (this.checkout.subscription!.originalPrice ? (this.checkout.subscription!.originalPrice | currency:'EUR':'symbol':'1.0-2') : (this.checkout.subscription!.price | currency:'EUR':'symbol':'1.0-2')) }}</div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card mt-3">
  <div class="card-header">
    <h6 class="mt-2">{{ "course:buy:payment:conditions:title" | translate }}</h6>
  </div>
  <div class="card-body">
    <div class="conditions" [ngClass]="{'hide-conditions': !showConditions}">
      <p *ngIf="!this.checkout.isGift">{{ "course:buy:payment:conditions:description:1" | translate }}</p>
      <p *ngIf="this.checkout.isGift">{{ "send:as:gift:course:condition" | translate }}</p>
      <p>{{ "course:buy:payment:conditions:description:2" | translate }}</p>
      <p class="mb-0">{{ "course:buy:payment:conditions:description:3" | translate }}</p>
    </div>
    <a class="btn btn-link" (click)="toggleConditions()" *ngIf="!showConditions">{{ "course:buy:payment:conditions:showFull" |  translate }}</a>
  </div>
</div>

<label class="fancy-radio-checkbox ms-3 mt-3">
  <input class="fancy-input" type="checkbox" [(ngModel)]="this.checkout.termsAccepted"/>
  <div class="fancy-graphic"></div>
  <span class="fs-14">{{ "course:buy:payment:iAgree" | translate }}</span>
</label>
