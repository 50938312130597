import { Component, OnInit, Input } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { ToastService } from 'src/app/services/toast.service';
import { CountryService } from 'src/app/services/country.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeactivateAccountComponent } from 'src/app/modals/profile/deactivate-account/deactivate-account.component';
import { subscriptionCancelModalComponent } from 'src/app/modals/profile/subscription-cancel-modal/subscription-cancel-modal.component';
import {
  faEye,
  faEyeSlash,
  faExclamationTriangle,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss'],
})
export class ProfileSettingsComponent implements OnInit {
  @Input('valor') user: any = null;

  // selectedFile: File | undefined;

  faExclamationTriangle = faExclamationTriangle;
  faCircleNotch = faCircleNotch;

  currentPasswordShow = faEyeSlash;
  newPasswordShow = faEyeSlash;
  retypePasswordShow = faEyeSlash;

  currentPasswordType = 'password';
  newPasswordType = 'password';
  retypePasswordType = 'password';

  imageOriginal = '';

  showImage = '';

  imgB64 = '';

  newPass = false;
  lowerCase = false;
  uperCase = false;
  num = false;
  special = false;
  lengthy = false;
  currentPass = false;

  notificationsData = {
    uploaded: false,
    commented: false,
  };

  emailData = {
    newEmail: '',
    oldEmail: '',
  };

  accountData = {
    country_code: 'NL',
    language: '',
  };

  passwordData = {
    current_password: '',
    new_password: '',
    repeat_new_password: '',
  };

  profileData = {
    display_name: '',
    bio: '',
  };

  countries: any = {};

  fgProfile!: UntypedFormGroup;
  fgProfileSubmit = false;

  fgEmail!: UntypedFormGroup;
  fgEmailSubmit = false;

  loadingProfile = false;
  loadingInfoAccount = false;
  loadingLanguage = false;
  loadingENotify = false;
  loadingNotifyConf = false;
  loadingPassword = false;
  constructor(
    private router: Router,
    private usersService: UsersService,
    private toastService: ToastService,
    private countryService: CountryService,
    private translateService: TranslateService,
    private ngbModal: NgbModal,
    private fbProfile: UntypedFormBuilder,
    private fbEmail: UntypedFormBuilder,
  ) {
    this.createProfileForm();
    this.createEmailForm();
  }

  ngOnInit(): void {
    
    
    this.countries = this.countryService.getCountryList();
    this.showImage = this.imageOriginal = this.user.avatarPathUrlMedium;

    this.accountData.language = this.user.language;
    this.accountData.country_code = this.user.country_code;

    this.emailData.newEmail = this.emailData.oldEmail = this.user.email;


    this.notificationsData.uploaded = !this.user?.organizations[0]
      ? false
      : this.user?.organizations[0].Organization_Member?.notificate_on_own;
    this.notificationsData.commented = !this.user.organizations[0]
      ? false
      : this.user?.organizations[0].Organization_Member?.notificate_on_commented;

    this.profileData.display_name = this.user.display_name || '';
    this.profileData.bio = this.user.bio || '';
   
    // console.log(this.user);
  }

  // Form upload image

  uploadImage(inputValue: any): void {
    var file: File = inputValue.target.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.imageOriginal = myReader.result as string; // Base64

      this.resizeImage(file);
    };

    myReader.readAsDataURL(file);
  }

  resizeImage(file: any) {
    var maxW = 800;
    var maxH = 800;

    var canvas = document.createElement('canvas');
    var context: any = canvas.getContext('2d');

    var img = document.createElement('img');
    var self = this;
    var result = '';

    img.onload = function () {
      var iw = img.width;
      var ih = img.height;

      var scale = Math.min(maxW / iw, maxH / ih);
      var iwScaled = iw * scale;
      var ihScaled = ih * scale;

      canvas.width = iwScaled;
      canvas.height = ihScaled;

      context.drawImage(img, 0, 0, iwScaled, ihScaled);

      result += canvas.toDataURL('image/jpeg', 0.5);
      //0.5 Resize

      self.showImage = result;

      //Base64

      // self.usersService.patchImage(self.user.username, self.showImage);
    };

    img.src = URL.createObjectURL(file);
  }

  onRemoveAvatar() {
    this,
      this.usersService
        .deleteImage(this.user.username)
        .then(() => {
          this.showImage =
            this.imageOriginal =
            this.user.avatarPathUrlMedium =
              '';
          this.toastService.showSuccess(
            this.translateService.instant('profile:avatar:remove:done')
          );
        })
        .catch((err) => {
          this.toastService.showError(err);
        });
  }

  // Form upload profile

  createProfileForm() {
    // Profile form
    this.fgProfile = this.fbProfile.group({
      display_name: ['', Validators.required], //  [Validators.required, Validators.minLength(4)]
      bio: ['', Validators.nullValidator],
    });
  }

  onSaveProfile() {
    if (this.fgProfile.invalid) {
      this.toastService.showError(
        this.translateService.instant('profile:edit:error')
      );
      return;
    }
    // this.fgProfileSubmit = true;
    // return console.log(this.fgProfile.value)
    // console.log('profile data', this.profileData);
    this.loadingProfile = true;

    this.usersService
      .putProfileAccount(this.user.username, this.profileData)
      .then((res: any) => {
        console.log(res);
        if (this.showImage != this.imageOriginal) {
          this.usersService
            .patchImage(this.user.username, this.showImage)
            .then(() => {
              this.toastService.showSuccess(
                this.translateService.instant('profile:edit:success')
              );
              this.loadingProfile = false;
            })
            .catch((err) => {
              this.toastService.showError(err);
              this.loadingProfile = false;
            });
        } else {
          this.toastService.showSuccess(
            this.translateService.instant('profile:edit:success')
          );
          this.loadingProfile = false;
        }
      })
      .catch((err) => {
        this.toastService.showError(err);
        this.loadingProfile = false;
      });
  }

  // Form change email

  createEmailForm() {
    // Profile form
    this.fgEmail = this.fbEmail.group({
      newEmail: ['', [Validators.required, Validators.email]],
    });
  }

  onChangeEmail() {
    if (this.fgEmail.invalid) {
      this.toastService.showError(
        this.translateService.instant('profile:edit:error')
      );
      return;
    }
    if (this.emailData.newEmail == this.emailData.oldEmail) {
      this.toastService.showError(
        this.translateService.instant(
          'my_profile:settings:account:same_email:error'
        )
      );
      return;
    }
    this.loadingENotify = true;
    this.usersService
      .patchEmail(this.emailData)
      .then((res: any) => {
       // console.log('Status Catch',res);
        if (res.status == 422) { 
          this.toastService.showError(
            this.translateService.instant('my_profile:settings:account:same_email:error')
            
            );
        }
        if (res.status == 401) {
          this.toastService.showError(res.error.message.friendly);
        }
        this.loadingENotify = false;
        if (res.result == 'success') {
          this.toastService.showSuccess(
            this.translateService.instant('profile:edit:success')
          );
          this.loadingENotify = false;
        }
      })
      .catch((err) => {
        console.log('Status Catch',err);
        this.toastService.showError(err);
        this.loadingENotify = false;
      });
  }

  // Form upload info account
  getTypeSubscription(){
    let plan=this.usersService.getPlanId()
    if (plan==1){
      return 'Monthly';
    }
    if (plan==2){
      return 'Yearly';
    }
    return 'Daily';
  }

  hasPlan(){
    return this.usersService.hasPlan()
  }
  cancelledPlanAt(){    
    return this.usersService.cancelledPlanAt()
  }
  expiredPlanAt(){    
    return this.usersService.expiredPlanAt()
  }
  onSaveInfoAccount(form: any) {
    if (form.invalid) {
      this.toastService.showError(
        this.translateService.instant('profile:edit:error')
      );
      return;
    }
    this.loadingInfoAccount = true;

    this.usersService
      .patchInfoAccount(this.user.username, form)
      .then(() => {
        this.toastService.showSuccess(
          this.translateService.instant('profile:edit:success')
        );
        this.loadingInfoAccount = false;
      })
      .catch((err) => {
        this.toastService.showError(err);
        this.loadingInfoAccount = false;
      });
  }
  // Form upload notifications

  setCommentVideo(value: any) {
    this.notificationsData.commented = value;
    // console.log('Comment',value);
  }
  setUploadVideo(value: any) {
    this.notificationsData.uploaded = value;
    // console.log('Upload:',value);Comment
  }

  onSaveNotificationsConf(form: any) {
    if (form.invalid) {
      this.toastService.showError(
        this.translateService.instant('profile:edit:error')
      );
      return;
    }
    this.loadingNotifyConf = true;
    this.usersService
      .patchNotificationsConf(this.user.username, this.notificationsData)
      .then((res: any) => {
        if (res.result == 'success') {
          this.toastService.showSuccess(
            this.translateService.instant(
              'my_profile:settings:notifications:conf:success'
            )
          );
          this.loadingNotifyConf = false;
        }
        if (res.status == 422) {
          this.toastService.showError(res.error.errors.message);
          this.loadingNotifyConf = false;
        }
        if (res.status == 401) {
          this.toastService.showError(res.error.message.friendly);
          this.loadingNotifyConf = false;
        }
      });
  }

  //Form upload password

  // Pattern password controler
  // At least 10-15 characters length
  // At least one lowercase
  // At least one uppercase.
  // At least one number.
  // At least one special character (.!@#$%^&*_= ...)al character (#$%ˆ&*()!@ ...)
  // It should be different than the last password
  checkPassword(entry: string, type: string = '') {
    let pass =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%ˆ&*()!@ ...]).{7,15}$/,
      lengthy = /^.{10,15}$/;
    if (/[a-z]/.test(entry)) {
      this.lowerCase = true;
    } else {
      this.lowerCase = false;
    }
    if (/[A-Z]/.test(entry)) {
      this.uperCase = true;
    } else {
      this.uperCase = false;
    }
    if (/[0-9]/.test(entry)) {
      this.num = true;
    } else {
      this.num = false;
    }
    if (/[#$%ˆ&*()!@ ...]/.test(entry)) {
      this.special = true;
    } else {
      this.special = false;
    }
    if (entry.match(lengthy)) {
      this.lengthy = true;
    } else {
      this.lengthy = false;
    }
    if (entry.match(pass)) {
      if (type == 'currentPass') this.currentPass = true;
      else {
        this.newPass = true;
      }
    } else {
      if (type == 'currentPass') this.currentPass = false;
      else {
        this.newPass = false;
      }
    }
  }

  rePasswordShow(type: string) {
    switch (type) {
      case 'currentPassword': {
        if (this.currentPasswordShow == faEyeSlash) {
          this.currentPasswordShow = faEye;
          this.currentPasswordType = 'text';
        } else {
          this.currentPasswordShow = faEyeSlash;
          this.currentPasswordType = 'password';
        }
        break;
      }
      case 'newPassword': {
        if (this.newPasswordShow == faEyeSlash) {
          this.newPasswordShow = faEye;
          this.newPasswordType = 'text';
        } else {
          this.newPasswordShow = faEyeSlash;
          this.newPasswordType = 'password';
        }
        break;
      }
      case 'retypePassword': {
        if (this.retypePasswordShow == faEyeSlash) {
          this.retypePasswordShow = faEye;
          this.retypePasswordType = 'text';
        } else {
          this.retypePasswordShow = faEyeSlash;
          this.retypePasswordType = 'password';
        }
        break;
      }
    }
  }

  onChangePassword(form: any) {
    if (form.invalid) {
      this.toastService.showError(
        this.translateService.instant('profile:edit:error')
      );
      return;
    }
    this.loadingPassword = true;
    let data = {
      current_password: this.passwordData.current_password,
      new_password: this.passwordData.new_password,
      repeat_new_password: this.passwordData.repeat_new_password,
    };
    this.usersService
      .patchPassword(this.user.username, data)
      .then((res: any) => {
        if (res.status == 422) {
          this.toastService.showError(res.error.errors.message);
        }
        if (res.status == 401) {
          this.toastService.showError(res.error.message.friendly);
        }
        if (res.result == 'success') {
          this.toastService.showSuccess(
            this.translateService.instant(
              'my_profile:settings:password:edit:success'
            )
          );
          this.passwordData.new_password =
            this.passwordData.repeat_new_password =
            this.passwordData.current_password =
              '';
          this.loadingPassword = false;
        }
      })
      .catch((err) => {
        this.toastService.showError(err);
        this.loadingPassword = false;
      });
  }

  // Form deactivate account

  onDeactivateAccount() {
    let modalRef = this.ngbModal.open(DeactivateAccountComponent, {
      windowClass: 'default-modal',
      size: '700',
      centered: true,
    });
    modalRef.componentInstance.user = {
      id: this.user.id,
      type: this.user.Organization_Member?.type || '',
      email: this.user.email,
    };
    modalRef.componentInstance.selfModal = modalRef;
  }

  // Form activate - comming son

  onActivateAccount() {}
  public onDeleteAccount() {
    this.router.navigate(['/profile','delete-account', this.user.id, this.user.username]);
  }
  public onCancelSubscription() {
    let modalRef = this.ngbModal.open(subscriptionCancelModalComponent, {
      windowClass: 'default-modal',
      size: '700',
      centered: true,
    });
    modalRef.componentInstance.user = {
      id: this.user.id,
      type: this.user.Organization_Member?.type || '',
      email: this.user.email,
    };
    modalRef.componentInstance.selfModal = modalRef;
  }
  
  
}
