<!-- <h5>{{ 'discount:course:coupon:question' | translate}}</h5>
<div class="input-group" [ngClass]="{'has-validation': codeError}">
  <input type="text" class="form-control" [ngClass]="{'is-invalid': codeError}" [(ngModel)]="code" [disabled]="this.checkout.discount">
  <button class="btn btn-primary" *ngIf="!this.checkout.discount" [disabled]="applyingCode" >{{ 'discount:course:coupon:apply:button' | translate }}<i *ngIf="applyingCode" class="fa fa-circle-notch fa-spin ms-2"></i></button>
  <button class="btn btn-outline-danger" *ngIf="this.checkout.discount"  ><i class="fa fa-times"></i></button>
  <div class="invalid-feedback" *ngIf="codeError">{{ codeError }}</div>
</div> -->

<div *ngFor="let sub of subscriptions_original; let i = index">
  <label class="fancy-radio-checkbox d-flex p-3 ps-5 mb-0">
    <input class="fancy-input" name="subscription_price"
      [value]="sub.id" type="radio" [(ngModel)]="initial" (ngModelChange)="onSubscriptionChange()">
    <div class="fancy-graphic ms-3"></div>

    <div>
      <h5 class="mb-1">{{ ('subscription:checkout:modal:text:1:'+sub.key) |translate}}</h5>
      <p  class="mb-0 fw-normal">{{ ("subscription:checkout:modal:text:2:"+sub.key)  |translate}}</p>
     </div>


    
    <h5 class="text-primary text-end flex-fill align-self-center mb-0">{{ sub.price | currency:'EUR':'symbol':'1.0-2'}}</h5>

  </label>
</div>

<div class="border p-3 mt-3">
  <div class="row">
    <p class="m-1"> {{'subscription:checkout:prolong:disclaimer' | translate}}</p>
   
  </div>
</div>
