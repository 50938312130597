import { Component, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceDetails } from 'src/app/interfaces/order';
import { CountryService } from 'src/app/services/country.service';
import { OrdersService } from 'src/app/services/orders.service';
import { ToastService } from 'src/app/services/toast.service';
import { CheckoutSubscriptionsService } from '../checkout-subscriptions.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-method-tab',
  templateUrl: './method-tab.component.html',
  styleUrls: ['./method-tab.component.scss'],
})
export class MethodTabComponent implements OnInit {
  countries: Array<any> = [];
  paymentMethods: Array<string> = [];
  vatInfo: any | undefined;

  constructor(
    public checkout: CheckoutSubscriptionsService,
    private ordersService: OrdersService,
    private countriesService: CountryService,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    
    this.paymentMethods = this.ordersService.getPaymentMethodsToSubscription();
    this.countries = this.countriesService.getCountryList();
    
    console.log('metodos',this.paymentMethods);
  }

  asInvoiceDetails(val: any): InvoiceDetails {
    return val;
  }
}
