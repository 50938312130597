
<div class="modal-close"  (click)="closeModal()">
    <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon><span >{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
    <h4 class="modal-title">{{'subscription:plan:cancel:title' | translate}}</h4>
</div>
<div class="modal-body">
    <p class="description--large">{{ 'subscription:plan:cancel:description' | translate }}</p>

    <div class="d-flex justify-content-end">
    <a class="btn btn-link font-weight-Light mx-2" (click)="closeModal()">
        <span>{{ 'users:deactivate:account:modal:description:button:cancel' | translate }}</span>
    </a>
        <button class="btn btn-danger" (click)="cancelPlan()" [disabled]="loading">
            <span>{{ 'subscription:plan:cancel:button:text' | translate }}</span>
            <fa-icon *ngIf="loading" class="ms-2" [icon]="faCircleNotch" [spin]="true"></fa-icon>    
        </button> 
    </div>
</div>