import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GroupsService } from 'src/app/services/groups.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-create-conversation',
  templateUrl: './create-conversation.component.html',
  styleUrls: ['./create-conversation.component.scss'],
})
export class CreateConversationComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() conversationId: any;
  @Input() conversationData: any;
  @Output() refresh: EventEmitter<any> = new EventEmitter();

  @Output('valor') createConversation: EventEmitter<Object> =
    new EventEmitter();
  public titleNameModal: string = 'Start';
  faTimes = faTimes;
  faCircleNotch = faCircleNotch;
  saveLoading: boolean = false;

  conversation: any = {
    title: '',
    description: '',
  };

  modulesQuill = {};

  boxStyle = {};

  // =============================================
  // All options Quill-editor
  // =============================================

  /* modulesQuill = {
    'emoji-shortname': true,
    'emoji-textarea': false,
    'emoji-toolbar': true,
    'toolbar': {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link', 'image', 'video'],                         // link and image, video
        ['emoji'],
      ],
      handlers: { 'emoji': function () { } }
    }
  } */

  constructor(
    private groupsService: GroupsService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {
    this.boxStyle = { height: '200px', width: '100%', border: 'none' };

    this.modulesQuill = {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],
          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          ['link'],
        ],
      },
    };

    /* this.modulesQuill = {
      'emoji-shortname': true,
      'emoji-textarea': false,
      'emoji-toolbar': true,
      'toolbar': {
        Container: [
        ['bold', 'italic', 'underline'],
        [{ align: [] }],
        [{ list: 'ordered'}, { list: 'bullet' }],
        ['link'],
        [{'emoji': function () {}}],
        ],
      }
    }; */
  }

  ngOnInit(): void {
    if (this.conversationId) {
      this.titleNameModal = 'Edit';
      this.conversation.title = this.conversationData.title;
      this.conversation.description = this.conversationData.description;
    }
  }

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  create(form: any) {
    this.saveLoading = true;
    if (this.conversationId) {
      this.groupsService
        .editConversation(this.conversationId, this.conversation)
        .then((resp) => {
          if (resp.status == 404) {
            this.toastService.showError(
              this.translate.instant('Error editing conversation')
            );
          } else {
            this.toastService.showSuccess(
              this.translate.instant('Conversation edited')
            );
          }
          this.refresh.emit();
          this.closeModal();
          this.saveLoading = false;
        })
        .catch((err: any) => {
          console.log(err);
          this.toastService.showError(
            this.translate.instant('Error editing conversation')
          );
        });
    } else {
      this.createConversation.emit(form.value);
    }
  }
}
