<div class="block" [ngClass]="containerClass">
  <div class="row file">
    <!-- <div class="file-list-icon pull-left ng-isolate-scope" style="background-image: url('assets/img/files/3.png')" 
        file="file"></div> -->
    <div class="avatar" *ngIf="showIcon">
      <app-user-icon
        size="medium"
        containerClass="resize-avatar-group"
        [user]="conversation.user"
      ></app-user-icon>
    </div>
    <div class="data col-8 col-md-10" (click)="leaveCommentaryModal(conversation)">
      <div class="title">{{ conversation?.title }}</div>
      <div class="the-creator mb-3">
        <span
          class="by-whom ng-binding"
          style="color: #999999; font-weight: 500; font-size: 14px"
          >{{'author:by' | translate}}
        </span>
        <span
          class="the-author ng-binding"
          style="color: #a251bd; font-weight: 500; font-size: 14px"
          >{{ conversation.user.display_name }}</span
        >
        <span style="color: #999999; font-weight: 500; font-size: 14px">
          ·
        </span>
        <span
          class="the-time"
          style="color: #999999; font-weight: 500; font-size: 14px"
          >{{ conversation?.created_at | amTimeAgo }}</span
        >
      </div>
      <div
        class="description short-description p-margin"
        [innerHtml]="processLinks(conversation.description)"
      ></div>
      <div class="comment-count" *ngIf="showIcon">
        <fa-icon class="commentIcon" [icon]="faComment"></fa-icon>

        <span
          class="fa fa-comment-o"
          style="color: #cdcdcd; font-size: 18px"
        ></span>
        <span class="comment-ammount">{{
          "groups:profile:files:responses:" +
            (conversation.count_comments == 1 ? "singular" : "plural")
            | translate: { count: conversation.count_comments }
        }}</span>
      </div>
    </div>
    <div class="col-1 text-end">
      <div
        *ngIf="userCanEdit(conversation)"
        ngbDropdown
        class="d-inline-block float_right"
        placement="bottom-right"
      >
        <button
          type="button"
          class="btn btn-outline-primary"
          id="dropdownBasic1"
          ngbDropdownToggle
        >
          <i class="fa fa-ellipsis-h"></i>
          <fa-icon
            *ngIf="saveLoading"
            class="ms-2"
            [icon]="faCircleNotch"
            [spin]="true"
          ></fa-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="mt-1">
          <button (click)="editConversation(conversation.id)" ngbDropdownItem>
            {{'Edit' | translate}}
          </button>
          <button
          *ngIf="tab != 'activity'"
            (click)="deleteConversation(conversation)"
            class="text-danger"
            ngbDropdownItem
          >
            {{'Delete' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
