<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 *ngIf="!editing" class="modal-title">
    {{
      fromVideo
        ? ("microclips:form:create:label" | translate)
        : ("course:create:microclip:title" | translate)
    }}
  </h4>
  <h4 *ngIf="editing" class="modal-title">
    {{ "microclips:form:editing" | translate }}
  </h4>
</div>
<div class="modal-body">
  <div class="form-group" *ngIf="!addingMicroclip">
    <div class="d-flex mb-1 justify-content-between">
      <div class="col-10 searchInput p-0">
        <input
          #searchInput
          autofocus
          [disabled]="sendingVideo || loadingAddMicrolclip"
          [(ngModel)]="searchText"
          (keypress)="searchOwn()"
          type="text"
          id="search"
          class="form-control search"
          [placeholder]="'search:button' | translate"
        />
        <i class="fas fa-search"></i>
        <ul
          [@inOutAnimation]
          class="suggestions-list"
          *ngIf="suggestions.length > 0"
        >
          <li *ngFor="let suggestion of suggestions" class="suggestion-item">
            <div
              class="row justify-content-between"
              (click)="selectVideo(suggestion)"
            >
              <div class="col-auto d-flex">
                <img class="img-video" [src]="suggestion?.thumb_url" />
                <p class="my-auto mx-3">
                  {{
                    suggestion?.info[0].name || suggestion?.info[0].description
                      | excerpt : 60
                  }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-auto btnSearchResponsive">
        <button
          [disabled]="sendingVideo || addingMicroclip || loadingVideos"
          (click)="searchOwn(true)"
          style="min-width: 135px"
          class="btn btn-primary w-100 searchButton text-nowrap"
        >
          {{ "search:button" | translate }}
          <fa-icon
            class="mr-2"
            [icon]="faCircleNorch"
            *ngIf="loadingVideos"
            [spin]="true"
          ></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="form-group">
    <p class="" *ngIf="!videoSelected">
      {{ "please:search" | translate }}
    </p>
  </div>
  <ng-container *ngIf="!addingMicroclip">
    <div class="form-group" *ngIf="videoSelected">
      <label class="modal-label" for="selected">{{
        "Selected Video:" | translate
      }}</label>

      <div id="selected" class="video-selected d-flex">
        <div class="col-auto">
          <img class="img-video-selected" [src]="videoSelected?.thumb_url" />
        </div>
        <div class="col-8">
          <h3 class="mb-2 ms-3 card-title">
            {{ videoSelected?.info[0]?.name | excerpt : 60 }}
          </h3>
          <p class="mb-0 ms-3">
            {{ videoSelected?.info[0]?.description | excerpt : 150 }}
          </p>
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          <p class="mb-0">
            {{ videoSelected?.source?.length | timeLength }}
          </p>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-auto">
          <button (click)="createMicroclip()" class="btn btn-primary">
            {{ "create:new:microclip" | translate }}
          </button>
        </div>
      </div>

      <label class="modal-label mb-0" for="microclips">{{
        "Microclips:" | translate
      }}</label>
      <div
        class="form-group"
        *ngIf="
          videoSelected.microclips.length == 0 ||
          videoSelected.microclips == null
        "
      >
        <p class="my-3">
          {{ "no:microclips" | translate }}
        </p>
      </div>
      <div class="form-group" *ngIf="videoSelected.microclips.length > 0">
        <div
          id="microclips"
          *ngFor="let microclip of videoSelected.microclips; let i = index"
          (click)="openVideo(microclip)"
          class="microclip-item d-flex col-12 align-items-center my-3 justify-content-between"
        >
          <div class="col-auto">
            <img
              class="img-video-micrloclip"
              [src]="microclip?.thumbnail_url"
            />
          </div>
          <div class="col-6">
            <h3 class="mb-2 ms-3 card-title">
              {{ microclip?.title | excerpt : 60 }}
            </h3>
            <p class="mb-0 ms-3">
              {{ microclip?.description | excerpt : 100 }}
            </p>
          </div>
          <div
            class="col-auto d-flex align-items-center justify-content-center"
          >
            <p class="mb-0">
              {{ microclip?.length | timeLength }}
            </p>
          </div>
          <div
            class="col-auto mx-1 d-flex align-items-center justify-content-center"
          >
            <p class="mb-0">
              ({{ (microclip?.start_time | timeLength) || "00:00" }} -
              {{ microclip?.end_time | timeLength }} )
            </p>
          </div>

          <div
            class="col-auto d-flex align-items-center justify-content-center"
          >
            <div class="actions">
              <button
                class="btn btn-action text-warning btn-sm"
                (click)="editingMicroclip(microclip)"
                data-toggle="tooltip"
                data-placement="top"
                title="Edit"
              >
                <i class="fa fa-pen"></i>
              </button>
            </div>
            <div class="actions mx-1">
              <button
                [disabled]="videoSelected.microclips[i]?.loadingDelete"
                class="btn btn-action text-danger btn-sm"
                (click)="deleteMicroclip(microclip, i)"
              >
                <fa-icon
                  class="mr-2"
                  [icon]="faCircleNorch"
                  *ngIf="videoSelected.microclips[i]?.loadingDelete"
                  [spin]="true"
                ></fa-icon>
                <i
                  *ngIf="!videoSelected.microclips[i]?.loadingDelete"
                  class="fa fa-trash"
                ></i>
              </button>
            </div>
            <div
              *ngIf="!fromVideo"
              class="icon-check"
              (click)="addToCourse(microclip)"
            >
              <i class="fas fa-plus-circle"></i>
            </div>
            <!-- <button
              [disabled]="loadingAddMicrolclip"
              (click)="addToCourse(microclip)"
              class="btn btn-outline-success"
            >
              {{ "add:to:course" | translate }}
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="addingMicroclip">
    <label class="modal-label" for="clip-title">{{
      "microclips:form:title:label" | translate
    }}</label>
    <input
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      type="text"
      [placeholder]="'microclips:form:title:placeholder' | translate"
      [(ngModel)]="newMicroclip.title"
    />
    <label class="modal-label" for="description">{{
      "microclips:form:title:description" | translate
    }}</label>
    <textarea
      class="input-box w-100"
      name="description"
      id="description"
      [placeholder]="
        'microclips:form:title:description:placeholder' | translate
      "
      cols="30"
      rows="5"
      [(ngModel)]="newMicroclip.description"
    ></textarea>
    <div class="d-flex justify-content-center mt-5">
      <div class="col-auto video" *ngIf="video">
        <app-video-player
          [video]="video"
          [getTime]="interval"
          [isMicroclip]="true"
          (sendSeconds)="getSeconds($event)"
        ></app-video-player>
      </div>
    </div>
    <div class="d-flex mt-3">
      <div class="col-auto">
        <button
          [disabled]="sendingVideo"
          (click)="create()"
          class="btn btn-primary"
        >
          {{ editing? ("microclips:form:edit:label" | translate) : ("create:new:microclip" | translate) }}
          <fa-icon
            class="mr-2"
            [icon]="faCircleNorch"
            *ngIf="sendingVideo"
            [spin]="true"
          ></fa-icon>
        </button>
      </div>
      <div class="col-auto mx-3">
        <button
          [disabled]="sendingVideo"
          (click)="cancel()"
          class="btn btn-danger"
        >
          {{ "Cancel" | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
