import { Injectable, ɵɵsetComponentScope } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Course } from 'src/app/interfaces/course';
import { InvoiceDetails, Order } from 'src/app/interfaces/order';
import { Subscription } from 'src/app/interfaces/subscription';
import { CountryService } from 'src/app/services/country.service';
import { OrdersService } from 'src/app/services/orders.service';
import { ToastService } from 'src/app/services/toast.service';
import { UsersService } from 'src/app/services/users.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutSubscriptionsService {
  public order: Order | undefined;
  public subscription: any | undefined;
  public course: Course | undefined;
  public discount: any | undefined;
  public termsAccepted = false;

  public isGift = false;
  public giftMembers: Array<{email: string}> = [];

  vatInfo: any | undefined;
  validateVatShowLoading = false;

  constructor(
    private countriesService: CountryService,
    private ordersService: OrdersService,
    private toastr: ToastService,
    private translate: TranslateService,
    private us: UsersService,
  ) { }

  changeIsCompany(val: boolean): void {
    (this.order!.invoice_details as InvoiceDetails).is_company = val;
  }

  onCountryCodeChange(): void {
    // Checks if the country is of the european union
    (this.order!.invoice_details as InvoiceDetails).is_eu_country = this.countriesService.isEuCountry((this.order!.invoice_details as InvoiceDetails).country_code);

    if ((this.order!.invoice_details as InvoiceDetails).is_company) {
        if ((this.order!.invoice_details as InvoiceDetails).is_eu_country) {
            // Invalidate vat
            this.invalidateCompanyVat();

            // Validate vat
            this.validateVat();
        } else {
            (this.order!.invoice_details as InvoiceDetails).is_company_vat_valid = false;
            this.vatInfo = null;
        }
    } else {
        // Clear company vat and coc
        (this.order!.invoice_details as InvoiceDetails).company_vat = '';
        (this.order!.invoice_details as InvoiceDetails).coc = '';

        // Invalidate vat
        this.invalidateCompanyVat();
    }

    this.calculateTotalAmount();
  }

  /**
   * Invalidate company vat
   */
  invalidateCompanyVat (): void {
    (this.order!.invoice_details as InvoiceDetails).is_company_vat_valid = false;
    (this.order!.invoice_details as InvoiceDetails).company_name = '';
    (this.order!.invoice_details as InvoiceDetails).company_address = '';
    this.vatInfo = null;
  }

  /**
   * Validate vat
   */
  validateVat (): void {
    if (!(this.order!.invoice_details as InvoiceDetails).is_company_vat_valid &&
      (this.order!.invoice_details as InvoiceDetails).company_vat &&
      this.countriesService.isEuCountry((this.order!.invoice_details as InvoiceDetails).country_code)) {

      // Show loading
      this.validateVatShowLoading = true;

      // Validate vat
      this.ordersService.validateVat({
        country_code: (this.order!.invoice_details as InvoiceDetails).country_code,
        vat_number: (this.order!.invoice_details as InvoiceDetails).company_vat
      }).then( (vatInfo) => {
        // Vat info
        this.vatInfo = vatInfo;

        if (this.vatInfo.valid) {
            (this.order!.invoice_details as InvoiceDetails).is_company_vat_valid = true;
            (this.order!.invoice_details as InvoiceDetails).company_name = this.vatInfo.name;
            (this.order!.invoice_details as InvoiceDetails).company_address = this.vatInfo.address;
        } else {
          this.toastr.showError(this.translate.instant('course:buy:payment:form:company_vat:invalid'));
        }

        // Show loading
        this.validateVatShowLoading = false;
      }).catch( (error) => {
        this.toastr.showError(this.translate.instant('course:buy:payment:form:company_vat:invalid'));

        // Show loading
        this.validateVatShowLoading = false;
      })
    }
  }

  getPriceWithDiscount(value: number) {
    if (!this.discount)
      return value;
    else if (this.discount.type === 'percentage')
      return value * (1 - this.discount.amount / 100);
    return Math.max(0, value - this.discount.amount);
  }

  calculateTotalAmount () {
    if (!this.subscription){return}
    this.order!.tax_percentage = this.countriesService.getCountryTax((this.order!.invoice_details as InvoiceDetails).country_code);
    this.order!.tax = ((this.subscription!.price) * this.order!.tax_percentage) / (1 + this.order!.tax_percentage);    
    this.order!.in_total = this.subscription!.subscription_days == this.course?.summaryOrders?.subscription_day_selected ? (this.subscription!.price) - this.order!.tax : (this.subscription!.originalPrice ? (this.subscription!.originalPrice) - this.order!.tax : (this.subscription!.price) - this.order!.tax);      
  }

  validateInvoice(showError = false): boolean {
    // Validate method
    if (!this.order!.method) {
      if (showError) {
        this.toastr.showError(this.translate.instant('form:error:required', {field: this.translate.instant('course:buy:payment:form:label:method')}));
      }

      return false;
    }

    // Validate firstname
    if (!(this.order!.invoice_details as InvoiceDetails).firstname) {
      if (showError) {
        this.toastr.showError(this.translate.instant('form:error:required', {field: this.translate.instant('course:buy:payment:form:label:1')}));
      }
      return false;
    }

    // Validate lastname
    if (!(this.order!.invoice_details as InvoiceDetails).lastname) {
      if (showError) {
        this.toastr.showError(this.translate.instant('form:error:required', {field: this.translate.instant('course:buy:payment:form:label:2')}));
      }
      return false;
    }

    // Validate country
    if (!(this.order!.invoice_details as InvoiceDetails).country_code) {
      if (showError) {
        this.toastr.showError(this.translate.instant('form:error:required', {field: this.translate.instant('course:buy:payment:form:label:4')}));
      }
      return false;
    }

    // Validate company
    if ((this.order!.invoice_details as InvoiceDetails).is_company) {
      if (!(this.order!.invoice_details as InvoiceDetails).company_name) {
        if (showError) {
          this.toastr.showError(this.translate.instant('course:checkout:company:required:fields:error'));
        }

        return false;
      }

      // Validate company address
      if (!(this.order!.invoice_details as InvoiceDetails).company_address) {
        if (showError) {
          this.toastr.showError(this.translate.instant('course:checkout:company:required:fields:error'));
        }

        return false;
      }
    }
    return true;
  }

  

  pay() {
    // Params
    var params = JSON.parse(JSON.stringify(this.order!));
    console.log('this.order',this.order)
    
    delete params.invoice_details.is_company_vat_valid;

    if (params.invoice_details.is_eu_country) {
        if (this.vatInfo) {
            params.invoice_details.company_name = this.vatInfo.name;
            params.invoice_details.company_address = this.vatInfo.address;
        } else {
            params.invoice_details.company_name = '';
            params.invoice_details.company_address = '';
        }
    } else {
        params.invoice_details.company_vat = '';
        params.invoice_details.coc = '';
    }

  
    params.isGift = false;
    params.subscription_type = this.subscription!.subscription_type;
//    console.log('params',params)

    // Saves the order
    return this.ordersService.save(params)
      .then( (order) => {
         console.log('order',order)
         console.log('params',params)       
         return this.ordersService.paySubscription(order);
      })
      .then( (response) => {
        window.open(response.data, '_self');
      })
      .catch( (error) => {
        this.toastr.showError(this.translate.instant('course:buy:payment:error'));
      });
  }
}
