import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderDetailComponent } from 'src/app/modals/admin/order/order-detail/order-detail.component';
import { AdminService } from 'src/app/services/admin.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-table-profile-orders',
  templateUrl: './table-profile-orders.component.html',
  styleUrls: ['./table-profile-orders.component.scss']
})
export class TableProfileOrdersComponent implements OnInit {
  @Input() listOrders: any[] = [];
  @Input() user: any = {};
  @Output() refreshTable = new EventEmitter<any>();
  @Output() scrolled = new EventEmitter<null>();

  @ViewChild('container') container!: ElementRef;
  constructor(
    private organizationService: OrganizationsService,
    private toastService: ToastService,
    private ngbModal: NgbModal,
    private router: Router,
    private orderService: AdminService
  ) {}

  ngOnInit(): void {
    this.listOrders = this.listOrders.filter((order:any) => order.payment_status=='paid' && order?.user?.email == this.user?.email);
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(e: any) {
    let bottomPosition =
      this.container.nativeElement.getBoundingClientRect().top +
      this.container.nativeElement.offsetHeight;
    if (bottomPosition < window.innerHeight) {
      this.scrolled.emit();
    }
  } 

  public shareSettings() {}

  
  public getLenght(item:any){
    if (item.type=='subscription'){
      return '-'
    }
     const days=item?.entries[0].subscription_days;
     let resp='';
     switch (days) {
      case 30:
        resp='1 month'
        break;
      case 90:
        resp='3 months'
        break;
      case 180:
        resp='6 months'
        break;
      case 365:
          resp='1 year'
          break;
      default:
        resp = ''
    }
    return resp;
  }
  
  public getPlanName(item: any){
    if (item.plan_id==1){
      return 'Monthly Subscription'
    }
    if (item.plan_id==2){
      return 'Yearly Subscription'
    }
    return 'Other Subscription'
  }
  public getLink(item:any){
   
    const addDays=item?.entries[0].subscription_days;
    if (!addDays||addDays<=0){
      return '/dh/profile';
    }
   
    return '/courses/'+item.collections[0].id
  }

  public getStart(item:any){
    if (item.type=='subscription'){
      return item.created_at
    }
    const days=item?.entries[0].subscription_days;   
    if (!days){
      return ''
    }else{
      return item.created_at
    }
   
  }

  public getPdf(item:any){
    return  environment.apiUrl + '/order/download/' + item.id;
  }

  public getFinishDate(item:any){
  //  console.log(item)
  if (item.type=='subscription'){
    return '-'
  }
    const originalDate = moment(item.created_at);
    const addDays=item?.entries[0].subscription_days;
    const newDate = originalDate.add(addDays, 'days');
    if (!addDays||addDays<=0){
      return '';
    }
    return newDate.format('DD MMM YYYY');
  }
  public openDetail(orderId: any) {
    let modalRef = this.ngbModal.open(OrderDetailComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.orderId = orderId;
  }
}
