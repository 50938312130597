import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MovieTabsWrapperComponent } from './movie-tabs-wrapper/movie-tabs-wrapper.component';
import { GridComponent } from './grid/grid.component';
import { CourseCardComponent } from './course-card/course-card.component';
import { PipesModule } from '../pipes/pipes.module';
import { OrganizationLogoComponent } from './organization-logo/organization-logo.component';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DndModule } from 'ngx-drag-drop';
import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingIconComponent } from './loading-icon/loading-icon.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { PlaylistCardComponent } from './playlist-card/playlist-card.component';
import { VideoCardComponent } from './video-card/video-card.component';
import { GroupCardComponent } from './group-card/group-card.component';
import { CarouselComponent } from './carousel/carousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { UserIconComponent } from './user-icon/user-icon.component';
import { CommentsContainerComponent } from './comments-container/comments-container.component';
import { CommentComponent } from './comment/comment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReviewStarsComponent } from './review-stars/review-stars.component';
import { ReviewListCardComponent } from './review-list-card/review-list-card.component';

import { EmptyContentComponent } from './empty-content/empty-content.component';
import { GroupPofileActivityComponent } from './group-profile-activity/group-profile-activity.component';
import { GroupActivityItemComponent } from './group-activity-item/group-activity-item.component';
import { FileCardComponent } from './file-card/file-card.component';
import { ConversationCardComponent } from './conversation-card/conversation-card.component';
import { GroupActivityItemCommentComponent } from './group-activity-item-comment/group-activity-item-comment.component';
import { GroupActivityItemVideoComponent } from './group-activity-item-video/group-activity-item-video.component';
import { GroupActivityItemCourseComponent } from './group-activity-item-course/group-activity-item-course.component';
import { GroupActivityItemPlayListComponent } from './group-activity-item-playlist/group-activity-item-playlist.component';
//import {GroupActivityItemFileComponent} from './group-activity-item-file/group-activity-item-file.component';
import { RouterModule } from '@angular/router';
import { ListComponent } from './list/list.component';
import { VideoComponent } from '../modals/video/video.component';
import { ContinueWatchingComponent } from './continue-watching/continue-watching.component';
import { ContinueCourseComponent } from './continue-course/continue-course.component';
import { CurriculumCard } from './curriculum/curriculum-card/curriculum-card.component';
import { SummaryIconsComponent } from './summary-icons/summary-icons.component';
import { ViewerLessons } from './curriculum/viewer-lessons/viewer-lessons.component';
import { ViewerParagraph } from './curriculum/viewer-paragraph/viewer-paragraph.component';
import { CurriculumMain } from './curriculum/curriculum-main/curriculum-main.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { SubscriptionShow } from './subscriptions-show/subscription-show.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { MicroclipModule } from './microclip/microclip.module';
import { MicroclipPlayerComponent } from './microclip/microclip-player/microclip-player.component';
import { SubscriptionsCardsComponent } from './subscriptions-cards/subscriptions-cards.component';
import { MainModalComponent } from './main-modal/main-modal.component';
import { CourseListCardComponent } from './course-list-card/course-list.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { VideoListCardComponent } from './video-list-card/video-list.component';
import { MlSwithComponent } from './ml-switch/ml-switch.component';
import { QuillModule } from 'ngx-quill';
import { SearchUsersComponent } from './search-users/search-users.component';
import { MembersListComponent } from './members-list/members-list.component';
import { SubHeaderInfoComponent } from './sub-header-info/sub-header-info.component';
import { CurriculumDraggableComponent } from './curriculum-draggable/curriculum-draggable.component';
import { TableDhTeamComponent } from './table-dh-team/table-dh-team.component';
import { TableOrganizationComponent } from './table-organization/table-organization.component';
import { TableAdminCourseProspectsComponent } from './table-admin-course-prospects/table-admin-course-prospects.component';
import { ToastsContainerComponent } from './toasts-container/toasts-container.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { SubscriptionBandComponent } from './subscription-band/subscription-band.component';
import { VotingStarsComponent } from './voting-stars/voting-stars.component';
import { PersonalDevelopmentComponent } from './personal-development/personal-development.component';
import { HomeReviewsComponent } from './home-reviews/home-reviews.component';
import { QuestionaireNotificationComponent } from './questionaire-notification/questionaire-notification.component';
import { MaintenanceNotificationComponent } from './maintenance-notification/maintenance-notification.component';

import { ContinueCourseHomeComponent } from './continue-course-home/continue-course-home.component';
import { LessonListCardComponent } from './lesson-list-card/lesson-list.component';
import { CircleComponent } from './circle/circle.component';
import { CoursePreviewHomeComponent } from './course-preview-home/course-preview-home.component';
import { AdminPlatformCardComponent } from './admin-platform-card/admin-platform-card.component';
import { TableAdminCourseComponent } from './table-admin-course/table-admin-course.component';
import { WidgetNotificationComponent } from './widget-notification/widget-notification.component';
import { WidgetMaintenancetNotificationComponent } from './widget-maintenance-notification/widget-maintenance-notification.component';

import { TableAdminOrdersComponent } from './table-admin-orders/table-admin-orders.component';
import { TableAdminUsersComponent } from './table-admin-users/table-admin-users.component';
import { TableAdminDiscountsComponent } from './table-admin-discounts/table-admin-discounts.component';
import { SearchCoursesComponent } from './search-courses/search-courses.component';
import { HealixComponent } from './healix/healix.component';
import { TableProfileOrdersComponent } from './table-profile-orders/table-profile-orders.component';
import { JoyrideModule } from 'ngx-joyride';

@NgModule({
  declarations: [
    MovieTabsWrapperComponent,
    GridComponent,
    CourseCardComponent,
    OrganizationLogoComponent,
    LoadingIconComponent,
    ProgressBarComponent,
    PlaylistCardComponent,
    VideoCardComponent,
    GroupCardComponent,
    CarouselComponent,
    UserIconComponent,
    CommentsContainerComponent,
    CommentComponent,
    ReviewStarsComponent,
    ReviewListCardComponent,
    EmptyContentComponent,
    UserIconComponent,
    GroupPofileActivityComponent,
    GroupActivityItemComponent,
    FileCardComponent,
    ConversationCardComponent,
    GroupActivityItemCommentComponent,
    GroupActivityItemVideoComponent,
    GroupActivityItemCourseComponent,
    GroupActivityItemPlayListComponent,
    ListComponent,
    VideoComponent,
    ContinueWatchingComponent,
    SummaryIconsComponent,
    ContinueCourseComponent,
    CurriculumCard,
    ViewerLessons,
    ViewerParagraph,
    CurriculumMain,
    ReviewsComponent,
    SubscriptionShow,
    VideoPlayerComponent,
    SubscriptionsCardsComponent,
    MainModalComponent,
    CourseListCardComponent,
    SearchInputComponent,
    VideoListCardComponent,
    MlSwithComponent,
    SearchUsersComponent,
    MembersListComponent,
    SubHeaderInfoComponent,
    CurriculumDraggableComponent,
    TableDhTeamComponent,
    TableOrganizationComponent,
    TableAdminCourseProspectsComponent,
    ToastsContainerComponent,
    ConfirmEmailComponent,
    SubscriptionBandComponent,
    VotingStarsComponent,
    PersonalDevelopmentComponent,
    HomeReviewsComponent,
    QuestionaireNotificationComponent,
    MaintenanceNotificationComponent,
    ContinueCourseHomeComponent,
    LessonListCardComponent,
    CircleComponent,
    CoursePreviewHomeComponent,
    AdminPlatformCardComponent,
    TableAdminCourseComponent,
    WidgetNotificationComponent,
    WidgetMaintenancetNotificationComponent,
    TableAdminOrdersComponent,
    TableAdminUsersComponent,
    TableAdminDiscountsComponent,
    SearchCoursesComponent,
    HealixComponent,
    TableProfileOrdersComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    NgbDropdownModule,
    DndModule,
    PipesModule,
    NgbModule,
    CarouselModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MicroclipModule,
    QuillModule.forRoot(),
    JoyrideModule.forChild()
  ],
  exports: [
    CurriculumDraggableComponent,
    FontAwesomeModule,
    MovieTabsWrapperComponent,
    GridComponent,
    OrganizationLogoComponent,
    LoadingIconComponent,
    CarouselComponent,
    UserIconComponent,
    CommentsContainerComponent,
    ReviewStarsComponent,
    ReviewListCardComponent,
    EmptyContentComponent,
    UserIconComponent,
    GroupPofileActivityComponent,
    GroupActivityItemComponent,
    FileCardComponent,
    ConversationCardComponent,
    GroupActivityItemCommentComponent,
    GroupActivityItemVideoComponent,
    GroupActivityItemCourseComponent,
    GroupActivityItemPlayListComponent,
    ListComponent,
    VideoComponent,
    ContinueWatchingComponent,
    SummaryIconsComponent,
    ContinueCourseComponent,
    ProgressBarComponent,
    CurriculumCard,
    ViewerLessons,
    ViewerParagraph,
    CurriculumMain,
    ContinueWatchingComponent,
    ReviewsComponent,
    SubscriptionShow,
    VideoPlayerComponent,
    MicroclipPlayerComponent,
    SubscriptionsCardsComponent,
    MainModalComponent,
    CourseListCardComponent,
    SearchInputComponent,
    VideoListCardComponent,
    MlSwithComponent,
    QuillModule,
    SearchUsersComponent,
    MembersListComponent,
    SubHeaderInfoComponent,
    TableDhTeamComponent,
    TableOrganizationComponent,
    TableAdminCourseProspectsComponent,
    ToastsContainerComponent,
    ConfirmEmailComponent,
    SubscriptionBandComponent,
    PersonalDevelopmentComponent,
    HomeReviewsComponent,
    QuestionaireNotificationComponent,
    MaintenanceNotificationComponent,
    ContinueCourseHomeComponent,
    LessonListCardComponent,
    CircleComponent,
    CoursePreviewHomeComponent,
    TableAdminCourseComponent,
    WidgetNotificationComponent,
    WidgetMaintenancetNotificationComponent,
    TableAdminOrdersComponent,
    TableAdminUsersComponent,
    TableAdminDiscountsComponent,
    SearchCoursesComponent,
    HealixComponent,
    TableProfileOrdersComponent,
    CourseCardComponent
  ],
})
export class SharedModule {}
