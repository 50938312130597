import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { UsersService } from 'src/app/services/users.service';
import { MainComponent } from 'src/app/modals/checkout-subscriptions/main/main.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-subscription-band',
  templateUrl: './subscription-band.component.html',
  styleUrls: ['./subscription-band.component.scss'],
})
export class SubscriptionBandComponent implements OnInit {
 
  auxEmail: string = ' ';
  constructor(
    private usersService: UsersService, 
    private ngbModal: NgbModal,
  ) {}

  ngOnInit(): void {}
  
  
  public userHasPlan() {
    return this.usersService.hasPlan()
  }

  openSubscriptionModal(){
    let modalRef = this.ngbModal.open(MainComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.initialDays = 30;
    modalRef.componentInstance.ngOnChanges();
  
}
}
