import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'src/app/interfaces/subscription';
import { CheckoutSubscriptionsService } from '../checkout-subscriptions.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { DiscountsService } from 'src/app/services/discounts.service';
import * as moment from 'moment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { LangService } from 'src/app/services/lang.service';
import { Organization } from 'src/app/interfaces/organization';
import { InitService } from 'src/app/services/init.service';

@Component({
  selector: 'app-plan-tab',
  templateUrl: './plan-tab.component.html',
  styleUrls: ['./plan-tab.component.scss'],
})
export class PlanTabComponent implements OnInit, OnChanges {
  @Input('subscriptions') subscriptions: any| undefined;
  @Input('initial') initial: number | undefined;
  @Input('isGift') isGift: boolean = false;
  @Output('changed') changed: EventEmitter<Subscription> = new EventEmitter();
  code = '';
  applyingCode = false;
  subscriptions_original: any;
  codeError: string | undefined;
  original_price: number | undefined;
  public currentLang: string = 'en';
  public links: any = {
    aboutEn: 'https://www.movielearning.com/en/movielearning-foundation',
    aboutNl: 'https://www.movielearning.com/nl/stichting-movielearning',
    termsEn: 'https://www.movielearning.com/en/terms-service',
    termsNl: 'https://www.movielearning.com/nl/algemene-voorwaarden',
    privacyEn: 'https://www.movielearning.com/en/privacy-policy',
    privacyNl: 'https://www.movielearning.com/nl/privacy-verklaring',
    disclaimerEn: 'https://www.movielearning.com/en/disclaimer',
    disclaimerNl: 'https://www.movielearning.com/nl/disclaimer',
    contactEn: 'https://www.movielearning.com/en/contact',
    contactNl: 'https://www.movielearning.com/nl/contact',
  };
  public currentOrganization: Organization | null = null;

  constructor(
    public checkout: CheckoutSubscriptionsService,
    private toastr: ToastService,
    private translate: TranslateService,
    private discountService: DiscountsService,
    private gtmService: GoogleTagManagerService,
    private lang: LangService,
    private initService: InitService
  ) {
    this.lang.language.subscribe((lang) => {
      this.currentLang = lang || 'en';
    });
  }

  ngOnInit(): void {
    this.currentOrganization = this.initService.getCurrentOrg();

    this.subscriptions_original = JSON.parse(
      JSON.stringify(this.subscriptions)
    );
    console.log(' this.subscriptions_original', this.subscriptions_original)
 
  }
   

 

   
  ngOnChanges(changes: SimpleChanges): void {
    this.onSubscriptionChange();
  }

  onSubscriptionChange(): void {
    //this.checkout.calculateTotalAmount();
    let sub = this.subscriptions?.find(
      (s:any) => s.id == this.initial
    );
   
    console.log('sub',sub)
 
   console.log(' this.checkout', this.checkout.order)
    this.checkout.order!.in_total = sub.price;
    this.checkout.order!.tax = 0;
    // console.log('this.checkout.',this.checkout.order)
    this.changed.emit(sub);
  }
  public sendGtm() {
    const gtmTag = {
      event: 'contact_us',
    };
    if (this.currentOrganization?.slug == 'courses') {
      console.log('Sending GTM', gtmTag);
      this.gtmService.pushTag(gtmTag);
    }
  }
}
